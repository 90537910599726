export default {
  'nav.account': 'Persoonlijk centrum',
  'nav.language': 'Vereenvoudigd Chinees',
  'signup.success': 'Aanmelding succesvol',

  //fout
  'contact.empty': 'Contactgegevens mogen niet leeg zijn',
  'phone.country.empty': 'Het telefoonvoorvoegsel mag niet leeg zijn',
  'phone.empty': 'De telefoon mag niet leeg zijn',
  'email.valid': 'Voer het juiste e-mailadres in',

  // basis
  'base.success': 'succes',
  'base.save': 'Opslaan',
  'base.slide': 'Trek de schuifregelaar uit om te verifiëren',
  'base.send.code': 'Verzend verificatiecode',
  'base.send.time': '({time}) verificatiecode opnieuw verzenden',
  'base.send.success': 'Succesvol verzenden',
  'base.view.more': 'Meer bekijken',
  'base.Language': 'Taal',
  'base.previous': 'Vorige pagina',
  'base.next': 'Volgende pagina',
  'base.total.page': "Totaal {pagina} pagina's",
  'base.go.to.page': 'Ga naar pagina',
  'base.go': 'ga naar',
  'base.followers': 'Volgers',
  'base.follow': 'volgen',
  'base.following': 'Gevolgd',
  'base.visit.store': 'Door winkel bladeren',
  'base.contact': 'Contacteer ons',
  'base.delivery': 'Geschatte levertijd: 1-7 dagen',
  'base.search': 'Zoeken',
  'base.search.text': 'Voer zoeken op trefwoord in',
  'base.server': 'Online klantenservice',

  //inhoudsopgave
  'text.categories.title': 'Productcategorie',
  'text.welcome': 'Welkom bij winkelen!',
  'text.wishlist': 'Vind ik leuk',
  'text.account': 'Persoonlijk centrum',
  'text.orders': 'Bestellingen',
  'text.messages': 'Berichten',
  'text.suggest.title': 'Exclusief aanbod',
  'text.suggest.content': 'Alleen voor nieuwe wereldwijde premium leden!',
  'text.super.deals': 'Topproducten, ongelooflijke prijzen.',

  //aanmelden
  'signup.register': 'Registreren',
  'signup.signin': 'Inloggen',
  'signup.store.signin': 'Zakelijke login',
  'signup.sign.out': 'Uitloggen',
  'signup.email': 'E-mailadres',
  'signup.password': 'Wachtwoord',
  'signup.qr.password': 'Bevestig wachtwoord',
  'signup.confirm.password': 'Bevestig wachtwoord',
  'signup.forgot.password': 'Wachtwoord vergeten',
  'signup.invitation': 'Uitnodigingscode',
  'signup.char': 'Teken',
  'signup.contains': 'Bevat cijfers, letters of symbolen',
  'signup.qr.contains': 'De twee wachtwoorden komen niet overeen',
  'signup.create.account': 'Account aanmaken',
  'signup.agree': 'Maak een account aan, je gaat akkoord',
  'signup.member.agreement': 'Lidmaatschapsovereenkomst',
  'signup.and': 'en',
  'signup.privacy.policy': 'Privacybeleid',
  'signup.email.code': 'E-mailverificatie',
  'signup.last.step': 'Laatste stap',
  'signup.send.email': 'Voer de 4-cijferige code in die is verzonden naar {email}',
  'signup.modify.email': 'E-mail wijzigen',
  'signup.verify.email': 'Verifieer e-mail',
  'signup.have.store': 'Heb een verkopersaccount',
  'signup.goto.signin': 'Zakelijke login',
  'signup.no.store': 'Geen handelaarsaccount',
  'signup.goto.store': 'Aanmeld handelaar',
  'signup.next': 'Volgende',
  'signup.your.email': 'uw e-mail',
  'signup.code.text': 'Verificatiecode',
  'signup.submit.signup': 'Meld je nu aan',
  'signup.smrz': 'Authenticatie op echte naam',
  'signup.derb': 'Tweede stap',
  'signup.qsrxm': 'Voer de naam van het document in',
  'signup.qsrhm': 'Voer het ID-nummer in',

  // vergeten
  'forgot.title': 'Wachtwoord opnieuw instellen',
  'forgot.btn.check': 'Verifieer e-mail',
  'forgot.reset.now': 'Reset nu',

  //winkel
  'store.info.open': '{y} weken geopend',

  // voettekst
  'footer.great.value': 'Grote waarde',
  'footer.great.value.desc': 'We bieden concurrerende prijzen voor meer dan 100 miljoen artikelen.',
  'footer.shopping': 'Wereldwijd winkelen',
  'footer.shopping.desc': "We verzenden naar meer dan 200 landen en regio's en onze website is beschikbaar in 7 talen.",
  'footer.safe.payment': 'Veilige betaling',
  'footer.safe.payment.desc': "Betaal met 's werelds populairste en veiligste betaalmethoden.",
  'footer.shop.with.confidence': 'Winkelen met vertrouwen',
  'footer.shop.with.confidence.desc': 'Ons kopersbeschermingsbeleid dekt uw gehele aankoopproces.',
  'footer.help.center': 'Helpcentrum',
  'footer.help.center.desc': 'Ondersteuning bij alle weersomstandigheden om een ​​vlotte winkelervaring te creëren.',
  'footer.terms.conditions': 'Algemene voorwaarden',
  'footer.return.policy': 'Retourbeleid',
  'footer.support.policy': 'Ondersteuningsbeleid',
  'footer.privacy.policy': 'Privacybeleid',
  'footer.be.seller': 'Verkoper worden',
  'footer.apply.now': 'Nu toepassen',
  'footer.stay.connected': 'Blijf verbonden',

  'footer.about.us': 'Over ons',
  'footer.about.company': 'Bedrijfsprofiel',
  'footer.about.video': 'Video-introductie',
  'footer.contact': 'Contactgegevens',

  'footer.my.account': 'Mijn account',
  'footer.my.logout': 'Uitloggen',
  'footer.my.order': 'Bestelgeschiedenis',
  'footer.my.wish': 'Mijn verlanglijstje',
  'footer.my.join': 'Word lid partner',
  'footer.email': 'E-mail',
  'footer.gfemail': 'Officiële e-mail',
  'footer.fwemail': 'Service-e-mail',
  'footer.address': 'Adres',

  'apply.success': 'Applicatie succesvol',
  'apply.success.desc': 'Succesvol toegepast, ga naar login-account',

  // categorieën
  'category.title': 'Verwante categorieën',
  'category.all': 'Alle categorieën',

  //detail
  'detail.store.home': 'Winkel Thuis',
  'detail.sale.items': 'Productlijst',
  'detail.recommend': 'Aanbevelen',
  'detail.orders': 'Verkoop',
  'detail.quantity': 'Aantal',
  'detail.pieces.available': 'Beschikbaar',
  'detail.delivery': 'Levering',
  'detail.free.shipping': 'Gratis verzending',
  'detail.estimated.delivery': 'Geschatte aankomst',
  'detail.days': 'dagen',
  'detail.buy.now': 'Kopen',
  'detail.add.to.cart': 'Toevoegen aan winkelwagen',
  'detail.buyer.protection': 'Kopersbescherming',
  'detail.money.guarantee': 'Geld-terug-garantie',
  'detail.refund.desc': 'Krijg een volledige terugbetaling als het artikel niet is zoals beschreven of niet is geleverd',
  'detail.description': 'Productbeschrijving',
  'detail.customer.reviews': 'Klantenbeoordelingen',
  'detail.specifications': 'Productspecificaties',
  'detail.top.selling.products': 'Best verkopende producten',
  'detail.recommended.for.you': 'Aanbevolen voor jou',
  'detail.sold': 'Verkoop',
  'detail.receipt': 'Bevestig ontvangst',
  'detail.receipt.title': 'Ontvangst bestelling bevestigen? ',
  'detail.receipt.content': 'Na bevestiging is de transactieopdracht voltooid',
  'detail.comment': 'Opmerking',
  'detail.refund.title': 'Bevestig aanvraag voor terugbetaling',
  'detail.refund.content': 'Na bevestiging wordt de bestelling terugbetaald',
  'detail.sqtk': 'Terugbetaling aanvragen',

  //bevestigen
  'confirm.shipping.address': 'adres ontvanger',
  'confirm.change': 'Wijzigen',
  'confirm.payment.methods': 'Betaalmethoden',
  'confirm.summary': 'Afrekening',
  'confirm.total.item.costs': 'Totaal items',
  'confirm.total': 'Totaal',
  'confirm.checkout': 'Ga naar de kassa',
  'confirm.place.order': 'Plaats nu een bestelling',
  'confirm.pay.now': 'Betaal nu',
  'confirm.order.desc': "Nadat ik op 'Bestelling plaatsen' heb geklikt, bevestig ik dat ik heb gelezen en bevestig",
  'confirm.order.policy': 'Alle voorwaarden en beleid',
  'confirm.payment': 'Global Shopping verzekert uw informatie- en betalingsbeveiliging',

  // adres
  'address.title': 'Ontvangstadres',
  'address.default': 'standaard',
  'address.edit': 'Bewerken',
  'address.delete': 'Verwijderen',
  'address.new': 'Maak een nieuw adres aan',
  'address.contact': 'Contact',
  'adres.adres': 'Adres',
  'address.phone': 'Telefoonnummer',
  'address.set.default': 'Stel standaard in',
  'address.confirm': 'Bevestigen',
  'address.cancel': 'Annuleren',
  'address.del.title': 'Bevestigen om adres te verwijderen?',
  'address.del.content': 'Weet u zeker dat u dit verzendadres wilt verwijderen?',

  'payment.method': 'Betaalmethode',

  // Winkelmand
  'shopping.title': 'Winkelwagen',
  'shopping.back.buy': 'Terug naar speciaal shoppen',
  'shopping.start.shopping': 'Begin met winkelen',

  // lid
  'member.account': 'Persoonlijk centrum',
  'member.overzicht': 'Overzicht',
  'member.orders': 'Bestellingen',
  'member.payment': 'Mijn Portemonnee',
  'member.address': 'Ontvangstadres',
  'member.wishlist': 'Vind ik leuk',
  'member.followlist': 'Volg lijst',
  'member.message.center': 'Berichtencentrum',
  'member.setting': 'Persoonlijke instellingen',
  'member.shop.info': 'Winkelinformatie',
  'member.shop.setting': 'Winkelinstellingen',
  'member.order.notify': 'Melding nieuwe bestelling',
  'member.order.tips': 'Door e-mails naar het e-mailadres van het verkopersaccount te sturen',

  // lid.bestelling
  'member.order.title': 'Mijn bestelling',
  'member.order.view.all': 'Bekijk alles',
  'member.order.all': 'Alle',
  'member.order.unpaid': 'onbetaald',
  'member.order.paid': 'Betaald',
  'member.order.procurement': 'aan te schaffen',
  'member.order.seller.paid': 'Gekocht',
  'member.order.processing': 'te verwerken',
  'member.order.shipped': 'verzonden',
  'member.order.return': 'Retourneren',
  'member.order.completed': 'Voltooid',
  'member.order.refund': 'Terugbetaald',
  'member.order.all.time': 'all',
  'member.order.empty': 'Nog geen bestelling',
  'member.order.date': 'datum',
  'member.order.purchase.date': 'Aankoop',
  'member.order.cpoy': 'Kopiëren',
  'member.order.id': 'Bestellen',
  'member.order.detail': 'Bestelgegevens',
  'member.order.logistics': 'Logistieke informatie',

  // lid. betaling
  'member.payment.title': 'Mijn Portemonnee',
  'member.wallet.balance': 'portemonneesaldo',
  'member.crypto.recharge': 'Opladen',
  'member.crypto.withdrawal': 'Intrekking',
  'member.crypto.bank': 'Bankkaart',
  'member.wallet.record': 'muntstortingsrecord',
  'member.bankcard.record': 'Bankkaartgegevens',
  'member.withdrawal.record': 'Intrekkingsrecord',
  'member.income.record': 'inkomensrecord',
  'member.transaction.record': 'Transactieoverzicht',
  'member.wallet.freeze': 'Tegoeden bevriezen',
  'member.wallet.profit': 'Geschatte inkomsten',

  //opladen
  'recharge.currency': 'Valuta',
  'opladen.protocol': 'Protocol',
  'recharge.qrcode': 'QR-code',
  'recharge.address': 'Portemonnee-adres',
  'recharge.copy': 'Adres kopiëren',
  'recharge.ok.text': 'Bevestigen',
  'recharge.cancel.text': 'Annuleren',

  //bank
  'bank.recharge.title': 'Bankkaart opwaarderen',
  'bank.title': 'Banknaam',
  'bank.name': 'naam',
  'bank.account': 'Rekening',
  'bank.routing': 'Routing',
  'bank.code': 'code',
  'bank.bankname': 'Banknaam',
  'bank.bankaddress': 'Bankadres',
  'bank.ok.text': 'Volgende',
  'bank.ok.prev': 'Vorige stap',
  'bank.submit': 'Nu indienen',
  'bank.amount': 'Bedrag opwaarderen',
  'bank.amount.desc': 'Voer het opwaardeerbedrag in',
  'bank.type': 'type',
  'bank.usd': 'USD',
  'bank.eur': 'Euro',
  'bank.ontvangst.nummer': 'Kwitantienummer',
  'bank.receipt.number.desc': 'Voer het transactiebewijsnummer in',
  'bank.credential.picture': 'Credential foto',
  'bank.credential.picture.desc': 'Upload de pasfoto',
  'bank.remark': 'Opmerking',
  'bank.upload': "Foto's uploaden",
  'bank.text.title': 'Notities',
  'bank.text.t1': 'Geen opmerkingen nodig voor overschrijving, leeg laten',
  'bank.text.t2': 'Selecteer Hongkong als het land van het huishouden in Hongkong, voeg China niet toe',
  'bank.text.t3': 'Overmakingen in de VS, op weekdagen, overmakingen naar Europa voor 15:00 uur, overmakingen zijn beschikbaar tijdens de werkuren van de bank!',
  'bank.text.t4': 'De betalingstijd is T+1 en de laatste betaling is T+3 in Europa',
  'bank.text.t5': 'Informeer het platform voordat u geld overmaakt om te bevestigen of de rekening beschikbaar is. Als de rekening wordt gesloten, wordt er geen vergoeding betaald.',
  'bank.text.t6': 'Storting via bankkaart. Klantenservice-informatie prevaleert.',

  //banklijst
  'bank.list.title': 'Banknaam',
  'bank.lijst.code': 'Bankcode',
  'bank.list.amount': 'Bedrag overboeken',
  'bank.list.number': 'Kwitantienummer',
  'bank.list.img': 'Voucher afbeelding',
  'bank.list.status': 'Status',
  'bank.lijst.tijd': 'tijd',
  'bank.list.status1': 'Wordt beoordeeld',
  'bank.list.status2': 'Geslaagd',
  'bank.list.status3': 'Afgewezen',

  // Opname
  'withdrawal.address.desc': 'Voer het adres van de portemonnee in!',
  'opname.nummer': 'Nummer',
  'withdrawal.real.number': 'werkelijke aankomst',
  'withdrawal.number.desc': 'Voer het opnamebedrag in!',
  'intrekking.btn.all': 'alle',
  'withdrawal.balance': 'Saldo',
  'withdrawal.commission': 'commissie',
  'withdrawal.actual.amount': 'Geboekt',
  'withdrawal.notice': 'Vragen',
  'withdrawal.notice.text': 'Bevestig voor het overdragen of de ontvangende adresgegevens correct zijn. Zodra de activa zijn overgedragen, kunnen ze niet meer worden geretourneerd.',
  'withdrawal.notice.content': '{name}({cp_name}) commissie: huidige marktwaarde {fee_rate}%/pen, minimum standaard: {fee_min} {name}/pen',
  'intrekking.submit': 'Verzenden',
  'withdrawal.choice': 'Kies een cryptocurrency',
  'withdrawal.yzm': 'Verificatiecode',
  'withdrawal.fs': 'Verzenden',
  'withdrawal.qsryzm': 'Voer de e-mailverificatiecode in',

  // ontvangen
  'recive.method': 'Protocol',
  'recive.amount': 'Bedrag',
  'recive.address': 'adres',
  'recive.date': 'tijd',
  'recive.status': 'Status',
  'recive.create.at': 'Transactietijd',
  'ontvangen.type': 'Type',
  'recive.befor': 'vóór transactie',
  'recive.balance': 'Saldo',
  'recive.freeze': 'Bevriezen',
  'recive.review': 'Bezig met beoordelen',
  'recive.success': 'Geslaagd',
  'recive.reject': 'Afgewezen',

  // adverteren
  'advertise.title': 'Promotiecentrum',
  'advertise.shop.title': 'Winkelpromotie',
  'advertise.shop.status': 'Status',
  'advertise.shop.expired': 'Vervallen',
  'advertise.shop.promotion': 'Promotie',
  'advertise.shop.expire.date': 'Vervaldatum',
  'advertise.shop.renew': 'Advertentie vernieuwen',
  'advertise.shop.payable': 'Te betalen bedrag',
  'advertise.shop.explanation': 'Advertentie uitleg',
  'advertise.shop.text': 'Doe mee aan platformadvertentiepromotie, verhoog de zichtbaarheid in de winkel en promoot transactieorders',
  'advertise.shop.paynow': 'Nu verlengen',
  'advertise.shop.modal.title': 'Bevestig betaling',
  'advertise.shop.modal.desc': 'Bevestigd om de promotiekosten te betalen',
  'advertise.shop.modal.btn': 'Bevestig betaling',

  //Wensenlijst
  'wishlist.title': 'Wishlist',
  'wishlist.delete': 'Verwijderen',
  'wishlist.orders': 'Verkoop',

  // Volg lijst
  'followlist.title': 'Volg lijst',
  'followlist.delete': 'Verwijderen',
  'followlist.follow': 'Volgen',

  // winkel
  'store.dashboard': 'Dashboard',
  'store.products': 'Producten',
  'store.products.list': 'Productlijst',
  'store.products.reviews': 'Productrecensies',
  'store.orders': 'Bestellingen',
  'store.wallet': 'Portemonnee',
  'store.message': 'Berichtencentrum',
  'store.setting': 'instelling',
  'store.order.total.profit': 'Geschatte totale bedrijfsopbrengsten',

  //dashboard
  'dashboard.store.hour.views': 'Realtime bezoeken',
  'dashboard.store.today.views': 'Bezoeken van vandaag',
  'dashboard.product.total': 'Totaal product',
  'dashboard.product.today': 'Nieuw vandaag',
  'dashboard.order.total': 'Totale bestelling',
  'dashboard.sales.total': 'Geschatte omzet',
  'dashboard.sales.real': 'Echte verkopen',
  'dashboard.sales.pay': 'Totale uitbetaling',
  'dashboard.sales.profit': 'Totale winst',
  'dashboard.commission.total': 'Totale omzet',
  'dashboard.commission.today': 'Inkomsten van vandaag',
  'dashboard.order.sales': 'Commodity-verkoop',
  'dashboard.sales.list': 'Verkoopranglijst',
  'dashboard.goods.cate.rate': 'Productclassificatieverhouding',
  'dashboard.goods.wish': 'Vind ik leuk product',
  'dashboard.january': 'januari',
  'dashboard.february': 'februari',
  'dashboard.march': 'maart',
  'dashboard.april': 'april',
  'dashboard.may': 'Mei',
  'dashboard.june': 'juni',
  'dashboard.july': 'Juli',
  'dashboard.augustus': 'augustus',
  'dashboard.september': 'september',
  'dashboard.oktober': 'Oktober',
  'dashboard.november': 'november',
  'dashboard.december': 'december',

  // producten
  'products.add.new': 'Nieuw product toevoegen',
  'products.add.from.warehouse': 'Voeg producten uit magazijn toe',
  'products.delete': 'Verwijderen',
  'products.add': 'Toevoegen',
  'products.table.img': 'afbeelding',
  'products.table.name': 'productnaam',
  'products.table.category': 'Categorie',
  'products.table.wish': 'vind ik leuk',
  'products.table.stock': 'voorraad',
  'products.table.price': 'Prijs',
  'products.table.profit': 'Winst',
  'products.table.action': 'Actie',
  'products.search.category': 'Zoeken op categorie',
  'products.back.product': 'Terug naar productlijst',
  'products.total': 'Totaal goederen',
  'products.yes': 'Ja',
  'products.no': 'Annuleren',
  'products.batch.add': 'Batchtoename',
  'products.ask.add': 'Weet je zeker dat je producten wilt toevoegen?',
  'products.batch.delete': 'Batch verwijderen',
  'products.ask.delete': 'Weet je zeker dat je het product wilt verwijderen?',
  'products.top': 'Top',
  'products.syzd': 'Startpagina bovenaan',
  'products.zdwz': 'Toppositie',
  'products.t1': 'Promotie',
  'products.t2': 'Voorkeur',
  'products.t3': 'Boetiek',
  'products.t4': 'geen',

  // recensies
  'reviews.title': 'Productrecensies',
  'reviews.product.name': 'Product',
  'reviews.user.name': 'Gebruiker',
  'reviews.star': 'Beoordeling',
  'reviews.comment': 'Commentaar inhoud',
  'reviews.sku': 'Productspecificaties',
  'reviews.imgs': 'Atlas',
  'reviews.created': 'Datum',

  // winkel.bestelling
  'store.order.purchase': 'Koop nu',
  'store.order.purchase.desc': 'Weet je zeker dat je voor dit product wilt betalen?',
  'store.order.purchase.yes': 'Betaal nu',
  'store.order.purchase.no': 'Annuleren',
  'store.order.desc': 'Voor het kopen van deze bestelling is betaling vereist en u krijgt voordelen nadat de bestelling is voltooid. ',
  'store.order.no': 'Bestelnummer',
  'store.order.number': 'Aantal',
  'store.order.buyer': 'Koper',
  'store.order.total': 'Totaalbedrag',
  'store.order.will.earning': 'Inkomen',
  'store.order.profit': 'Winst',
  'store.order.dividends': 'Dividenden',
  'store.order.payment.status': 'Betalingsstatus',
  'store.order.seller.buy.status': 'Aankoopstatus',
  'store.order.status': 'Bestelstatus',
  'store.order.date': 'besteldatum',
  'store.order.purchase.date': 'Aankoopdatum',
  'store.order.action': 'Bewerking',
  'store.order.purchase.amount': 'Aankoopbedrag',

  //inkomen
  'income.create.at': 'Recordtijd',
  'income.order.sn': 'Bestelnummer',
  'income.realpay': 'Bestelbedrag',
  'inkomen.winst': 'inkomen',

  //Instelling
  'setting.avatar': 'Zakelijke Avatar',
  'setting.upload': 'Uploaden',
  'setting.shop.name': 'Winkelnaam',
  'setting.shop.phone': 'Contact Telefoon',
  'setting.shop.address': 'Winkeladres',
  'setting.shop.save': 'Opslaan',
  'setting.upload.pic': 'Upload foto',
  'setting.send.pic': 'Stuur foto',

  //beseller
  'beseller.title': 'Solliciteer om handelaar te worden',
  'beseller.account': 'Accountgegevens',
  'beseller.store': 'Winkelinformatie',
  'beseller.store.name': 'Winkelnaam',
  'beseller.store.address': 'Winkeladres',

  //bewaar thuis
  'store.home.title': 'Startpagina-instellingen',
  'store.home.topimg': 'bovenste afbeelding',
  'store.home.banner': 'Carrouselafbeelding',
  'store.home.up3': 'Opmerkingen: Minstens 3 uploads',
  'store.upload.more': "Meer foto's uploaden",
  'store.home.columns': 'Kolommen',
  'store.home.bgimg': 'Achtergrondafbeelding',
  'store.goods.remark': 'Opmerking: 5 stuks per regel, maximaal 10 stuks per bord',
  'store.home.select': 'Selecteer product',
  'store.home.add': 'Kolom toevoegen',

  //persoonlijke informatie
  'setting.update.user': 'Persoonlijke informatie',
  'setting.update.info': 'Informatie wijzigen',
  'setting.user.avatar': 'Gebruikersavatar',
  'setting.user.nickname': 'Gebruikersbijnaam',
  'setting.user.nickname.desc': 'Voer gebruikersnaam gebruikersnaam in',

  'setting.safe.title': 'Veiligheidsinformatie',
  'setting.user.passwd': 'Wachtwoord wijzigen',
  'setting.passwd.title1': 'Oud wachtwoord',
  'setting.passwd.desc1': 'Voer het oude wachtwoord in',
  'setting.passwd.title2': 'Nieuw wachtwoord',
  'setting.passwd.desc2': 'Voer een nieuw wachtwoord in',
  'setting.passwd.title3': 'Bevestig wachtwoord',
  'setting.passwd.desc3': 'Voer een bevestigingswachtwoord in',
  'setting.invitation': 'Vrienden uitnodigen',
  'setting.copy': 'Kopiëren',

  'adv.add.products': 'Voeg actieproducten toe',
  'adv.list.title': 'Productpromotie',
  'adv.type': 'Promotietype',
  'adv.begin.time': 'Promotietijd',
  'adv.end.time': 'Eindtijd',
  'adv.status': 'Status',
  'adv.status.s1': 'Promoten',
  'adv.status.s2': 'Einde',
  'adv.add.back': 'Terug naar de gepromote lijst',
  'adv.recharge': 'Actiepunten opwaarderen',
  'adv.select.goods': 'Selecteer goederen',
  'adv.select.btn': 'Gepromote producten',
  'adv.recharge.title': 'Opwaarderen Promotiepunten',
  'adv.recharge.balance': 'Puntensaldo',
  'adv.punt': 'punt',
  'adv.point.rate': 'Puntverhouding',
  'adv.recharge.amount': 'Oplaadbedrag',
  'adv.recharge.input.amount': 'Voer oplaadbedrag in',
  'adv.select.mod': 'Selecteer paneel',
  'adv.mod1': 'Aanbevolen kolommen',
  'adv.mod2': 'Voorkeurskolom',
  'adv.mod3': 'Hot sale kolom',
  'adv.mod4': 'Uitstekende lijst',
  'adv.mod5': 'Classificatielijst',
  'adv.mod6': 'Gedetailleerde promotie',
  'adv.ge': 'Aantal',
  'adv.xs': 'uur',
  'adv.xzbk': 'Selecteer plaat',
  'adv.syme': 'Resterende quota',
  'adv.tgsc': 'Duur van de actie',
  'adv.tgjg': 'Actieprijs',
  'adv.syye': 'Huidig ​​saldo',
  'adv.tgye': 'Promotiesaldo',
  'adv.yj1': 'verwacht',
  'adv.yj2': 'Start promotie',
  'adv.pay.point': 'Verbruikspunten',

  'wallet.ykcsxf': 'De administratiekosten zijn afgetrokken',
  'wallet.sxf': 'Servicekosten',
  'wallet.zxje': 'Minimumbedrag',
  'wallet.24hxe': '24-uurs limiet',
  'wallet.desc.text': 'De ambtenaar zal je in geen geval vragen om geld over te maken naar een rekening, noch om een ​​verificatiecode. Neem niet deel aan illegale activiteiten zoals aankopen voor anderen, witwassen van geld en illegale fondsenwerving, en pas op voor online fraude',
  'wallet.cunbi.text': 'Je kunt alleen {naam} op dit adres storten, andere activa worden niet opgehaald',

  'credit.title': 'Kredietlimiet',
  'credit.ed': 'Quota',
  'credit.hk': 'Terugbetaling',
  'credit.kyye': 'Beschikbaar saldo',
  'credit.dqqk': 'Huidige achterstand',
  'credit.hkje': 'Aflossingsbedrag',
  'credit.hkje.desc': 'Vul het aflossingsbedrag in',
  'credit.cgje': 'Aankoopbedrag',
  'credit.qrz': 'Ga naar authenticatie',
  'credit.srxyj': 'Voer het tegoedbetalingsbedrag in',

  'store.gzl': 'Aandachtsbedrag',
  'store.wxz': 'Onbeperkt',

  'auth.smrz': 'Authenticatie op echte naam',
  'auth.rzlx': 'Type',
  'auth.sfzm': 'ID-kaart voorzijde',
  'auth.sffm': 'De achterkant van de ID-kaart',
  'auth.zsxm': 'echte naam',
  'auth.zjhm': 'Documentnummer',
  'auth.yyzz': 'Zakelijke Licentie',
  'auth.gsmc': 'Bedrijfsnaam',
  'auth.shz': 'Auditing',
  'auth.ytg': 'Geslaagd',
  'auth.ybh': 'Afgewezen',
  'auth.zt': 'status',
  'auth.gr': 'Persoonlijk',
  'auth.gs': 'Onderneming',
  'auth.ljtj': 'Authenticatie aanvragen',
  'auth.wrz': 'Niet geverifieerd',

  'credit.qts': 'Bekijk beschrijving',
  'store.spss': 'Bovengrens grondstoffen',
  'store.dpfh': 'Bewaar dividenden',
  'store.qydj': 'Aandelenniveau',
  'store.level': 'niveau',
  'store.jian': 'stukjes',

  'order.notify.email': 'Vanwege risicobeheersing per e-mail zullen herinneringsmails voor platformbestellingen regelmatig worden onderschept. Om ze op tijd te ontvangen, wordt aanbevolen om {e} toe te voegen aan de witte lijst voor aanmeldings-e-mails',

  'setting.sjxx': 'Bedrijfsinformatie',
  'setting.smrz': 'Authenticatie op echte naam',
  'setting.aqzx': 'Beveiligingscentrum',
  'setting.tzxx': 'Meldingsinformatie',
  'setting.dpzx': 'Decoratie homepage',
  'setting.sjqy': 'Zakelijke belangen',
  'setting.sjdjqy': 'Beschrijving van voordelen op bedrijfsniveau',

  'setting.fhbky': 'Het huidige dividend is niet beschikbaar',
  'setting.spgzyzy': 'Winkelregels en richtlijnen',

  'desc.dqqy': 'Vandaag eigen vermogen',
  'desc.xjqy': 'Ondergeschikte belangen',
  'desc.sjqy_0': 'Kredietlimiet opslaan',
  'desc.sjqy_1': 'Bewaar dividenden',
  'desc.sjqy_2': 'Aantal producten dat gepubliceerd kan worden',

  'share.yqlb': 'Uitnodigingslijst',
  'share.zmj': 'Totaal verkoper',
  'share.zdd': 'Totale bestelling',
  'share.zsy': 'Totale makelaarsinkomsten',
  'share.wcl': 'onverwerkt',
  'share.ycl': 'Verwerkt',
  'share.ywc': 'Voltooid',
  'share.dpmc': 'Naam winkel',
  'share.ddl': 'Bestelhoeveelheid',
  'share.jjsy': 'Inkomsten uit makelaardij',
  'share.zts': 'Totaal aantal',

  'chat.state': 'staat',
  'chat.zx': 'Online',
  'chat.ljz': 'Verbinden',

  'bind.wallet.title': 'Intrekkingsadres',
  'bind.address.title': 'Adres toevoegen',
  'bind.bj': 'Bewerken',
  'bind.sc': 'Verwijderen',
  'bind.qrsc': 'Weet je zeker dat je dit adres wilt verwijderen?',
  'bind.qbdz': 'Wallet-adres',
  'bind.tjsj': 'Tijd toevoegen',
  'bind.cz': 'Bediening',
  'withdrawal.address.choice': 'Kies a.u.b. het herroepingsadres!',

  'order.sxsj': 'Effectieve tijd',
  'order.ljsj': 'onmiddellijk van kracht',
  'order.dssj': 'Getimed effectief',

  'store.st.zt': 'Winkelstatus',
  'store.st.zc': 'normaal',
  'store.st.zc.desc': 'Uw winkel is normaal, ga zo door',
  'store.st.yc': 'Uitzondering',
  'store.st.yc.desc': 'Je winkel heeft abnormale bestellingen of klachten van klanten ontvangen',
  'store.st.dj': 'Bevriezen',
  'store.st.dj.desc': 'Uw winkel heeft een bepaald operationeel risico en de tegoeden zijn bevroren',
  'store.st.jy': 'uitgeschakeld',
  'store.st.jy.desc': 'Je winkel heeft de platformgerelateerde afspraken en regels geschonden en is uitgeschakeld',

  'task.sy': 'Startpagina',
  'task.rwlbo': 'Takenlijst',
  'task.rwxqo': 'Taakdetails',
  'task.ljcy': 'Doe nu mee',
  'task.gdrw': 'Meer taken',
  'task.rwlb': 'Lijst met grijptaken',
  'task.qbrw': 'Alle taken',
  'task.wcyd': 'Ik heb meegedaan',
  'task.qdz': 'Opdrachten krijgen',
  'task.ywc': 'Voltooid',
  'task.djy': 'te verhandelen',
  'task.ddje': 'Bestelbedrag',
  'task.cyrs': 'Aantal deelnemers',
  'task.ewsy': 'Extra voordelen',
  'task.jzsj': 'Termijn',
  'task.ckxq': 'Bekijk details',
  'task.rwxq': 'Taakgegevens',
  'task.cylb': 'Deelnamelijst',
  'task.qdcyrw': 'Weet je zeker dat je wilt deelnemen aan deze bestellingstaak?',
  'task.sd': 'Ja',
  'task.bue': 'nee',

  'task.wdsy': 'Inkomsten',
  'task.wdfh': 'Dividenden',
  'task.wdqd': 'Bestelling ophalen',
  'task.dd.leixin': 'Bestellingstype',
  'task.dd.qdrw': 'Taak snel bestellen',
  'task.dd.ptdd': 'Gewone bestelling',

  'flow.lltg': 'Flow-bevordering',
  'flow.gmll': 'Datapakket kopen',
  'flow.dqtc': 'Huidig ​​pakket',
  'flow.tc1': 'Pakket',
  'flow.sy2': 'overig',
  'flow.tian3': 'dag',
  'flow.jlzs': 'Totaal aantal records',
  'flow.lltc': 'Flow-pakket',
  'stroom.ll': 'stroom',
  'flow.gmsj': 'Aankooptijd',
  'flow.tcjg': 'Pakketprijs',
  'flow.sjzf': 'daadwerkelijke betaling',
  'flow.tcsm': 'Pakketbeschrijving',
  'flow.tcsm.sm': 'Als u dit pakket koopt, vergroot u de zichtbaarheid van de winkel en trekt u meer klantenverkeer aan. Om meer bestellingen binnen te halen en de winkelomzet te verhogen. ',

  'flow.tcmc': 'Pakketnaam',
  'flow.gmsc': 'Aankoopduur',
  'flow.zje': 'Totaal bedrag',
  'flow.jzsj': 'Termijn',
  'flow.gmrq': 'Aankoopdatum',
  'flow.tian1': 'dag',
  'flow.zhou1': 'week',
  'flow.yue1': 'maand',

  'share.dj': 'niveau',

  'share.hz': 'Overdracht',
  'share.hzd': 'Overbrengen naar',
  'share.qbye': 'portemonneesaldo',
  'share.tgje': 'Actiebedrag',
  'share.hzje': 'Bedrag overmaken',
  'share.qsrhzje': 'Voer het overboekingsbedrag in',

  '_inv._title': 'Beschrijving uitnodiging',
  '_inv._t1': '1. Uitnodiging en uitgenodigde relatie',
  '_inv._desc1': 'A nodigt B uit, B nodigt C uit, C nodigt D uit. Het hoogste niveau is 4, als D opnieuw E uitnodigt, dan heeft E niets met A te maken. ',
  '_inv._t2': '2. De uitnodiger krijgt een beloning',
  '_inv._desc2': 'A krijgt 4% van het totale bedrag voltooid door bestelling B, A krijgt 2% van het totale bedrag voltooid door bestelling C en A krijgt 1% van het totale bedrag voltooid door bestelling D',

  '_index._gm': 'Aankoop',
  '_index._sm': 'Beschrijving: vergroot winkelbereik en trek verkeer aan',

  '_shop._update': '{n} keer/maand, {m} keer gewijzigd in de huidige maand',

  '_chat._hc': 'Intrekken',

  '_footer._suom': 'Beleidsbeschrijving',

  '_footer._platform._qualification': 'Bedrijfskwalificatie',
  '_store._ptzz': 'Volgende winkelplatformgerelateerde kwalificaties',

  '_abt._gywm': 'Over ons',
  '_abt._syms': 'Bedrijfsmodel',
  '_abt._qyzr': 'Bedrijfsverantwoordelijkheid',
  '_abt._lxwm': 'Neem contact met ons op',

  '_wsj._wsjms': 'Vier Halloween in stijl in onze griezelige Halloweenwinkel. Of je nu op zoek bent naar een trucje of een traktatie tijdens dit griezelige seizoen, onze collectie Halloweenkostuums, -versieringen en -accessoires heeft iets voor het hele gezin. een huiveringwekkend statement met onze dames- en herencollecties, of laat de fantasie van uw kind de vrije loop met verkleedkostuums. Van onvergetelijke schattige huishoudelijke artikelen en speelgoed tot glamoureuze schoonheid en cosmetica: vind de perfecte stukken om de meest opwindende feestdag van het jaar te vieren. ',

  '_wsj._b1': 'Herfst woondecoratie',
  '_wsj._b2': 'Pompoen',
  '_wsj._b3': 'Thuis',
  '_wsj._b4': 'Kinderen',
  '_wsj._b5': "Baby's",
  '_wsj._b6': 'Dames',
  '_wsj._b7': 'Verkleedkleding',
  '_wsj._b8': 'Gepersonaliseerd',

  '_wsj._cj': 'Deelnemen',
  '_wsj._gz': 'Activiteitsregels',
  '_wsj._flss': 'Categorie zoeken',
  '_wsj._wsnz': 'Halloween dameskleding',
  '_wsj._wsn': 'Halloween herenkleding',
  '_wsj._wst': 'Halloween kinderkleding',
  '_wsj._wszs': 'Halloween-versiering',
  '_wsj._wslp': 'Halloweencadeau',

  '_wsj._desc1': '"Halloween Special, Trick or Treat!" In oktober hebben we een carnavalsfeest voor je voorbereid! Kom allerlei enge en schokkende producten shoppen, er wachten grote kortingen op je! ',
  '_wsj._lkm': 'Ga winkelen',

  '_wsj._wsj': 'Halloween',

  '_wsj._wsjjz': 'Halloween komt eraan',
  '_wsj._mzbt': 'Schoonheidsadventskalenders',
  '_wsj._mznr': 'Het aftellen naar de mooiste tijd van het jaar is zojuist begonnen met adventskalenders voor iedereen.',

  '_chr._tt1': 'Winkel de hele kerst',
  '_chr._tt2': 'Kerstversieringen',
  '_chr._tt3': 'Shop alle cadeaus',
  '_chr._tt4': 'Shop alle kerstnachtkleding',

  '_chr._dt1': 'De aanloop naar Kerstmis is net zo magisch als de grote dag. Dus zorg dat je cadeautjes klaar zijn, zorg dat je gang versierd is en maak je bijpassende pyjama klaar voor de magie van Kerstmis.',

  '_chr._dtt1': 'Kerstkleding',
  '_chr._dtt2': 'Bijpassende familiekerstmis',
  '_chr._dtt3': 'Adventskalenders',
  '_chr._dtt4': 'Kerstmis thuis',
  '_chr._dtt5': 'Gezellige winkel',
  '_chr._dtt6': 'Meubels op tijd voor Kerstmis',

  '_chr._tre1': 'Nu populair',
  '_chr._tre2': 'Voeg een beetje feestelijk plezier toe aan je huis met de trends waar iedereen van houdt.',
  '_chr._tre3': 'Peperkoek',
  '_chr._tre4': 'Kerstpudding',
  '_chr._tre5': 'Kerstmisgonks',

  '_chr._ttre1': 'Kaarten en inpakpapier',
  '_chr._ttre2': 'Speelgoed & Spellen',
  '_chr._ttre3': 'Eten en drinken cadeaus',
  '_chr._ttre4': 'Kousvullers',
  '_chr._ttre5': 'Geheime Kerstman',
  '_chr._ttre6': "Baby's eerste kerst",
  '_chr._ttre7': 'Schoonheidscadeaus',
  '_chr._ttre8': 'Premium cadeaus',
  '_chr._ttre9': 'Gepersonaliseerde cadeaus',

  '_sdj._sdnz': 'Dameskleding',
  '_sdj._sdn': 'Herenkleding',
  '_sdj._sdetlw': 'geschenk',
  '_sdj._sdzs': 'Decoratie',
  '_sdj._sdjj': 'speeltje',

  '_sdj._title1': 'Kerstmis komt eraan',
  '_sdj._desc1': "Kerstmis: de tijd van geven en dankbaarheid.' In december hebben we een vrolijk versierd en warm familiefeest voor je voorbereid! Kom en pak een verscheidenheid aan heldere en warme producten met grote kortingen die op je wachten! ",

  '_ssd._lhpp': 'Flexibele betaling',
  '_ssd._lzhq': 'Bron globaal',
  '_ssd._pwyx': 'beoordeeld als uitstekend',
  '_ssd._qsth': 'Gemakkelijk retourneren',
  '_ssd._jgppcn': 'Prijsmatching en commitment',
  '_ssd._ztbt': 'Creëer een wereldwijd one-stop-shopping',
  '_ssd._yzspl': 'Voorraad rechtstreeks inkopen bij meer dan 8.000 onafhankelijke merken',
  '_ssd._zcgm': 'Registreer om te kopen',
  '_ssd._zccs': 'Registreer voor verkoop',

  '_ssd._dlwz': 'Inloggen en registreren',

  '_ssd._qjrgwc': 'Voeg alles toe aan winkelwagen',

  '_zddy._gzlb': 'Volglijst',
  '_zddy._zsersxs': 'Verkocht bij shopifyoutlets',
  '_zddy._zccwsj': 'Registreer als verkoper',
  '_zddy._dlyh': 'Inloggen',
  '_zddy._zcyh': 'Registreren',
  '_zddy._xyh': 'Nieuwe gebruiker',
  '_zddy._gwc': 'Winkelwagen',
  '_zddy._znxx': 'Bericht',
  '_zddy._zshy': 'Meest populair',
  '_zddy._ddl': 'Bestelling',
  '_zddy._mysj': 'Geen gegevens',
  '_zddy._ckgd': 'Zie meer',
  '_zddy._tjsp': 'Aanbevolen producten',
  '_zddy._rmsp': 'Populaire producten, nieuwe trends, meer aankoopmogelijkheden',
  '_zddy._xsp': 'Nieuw product',
  '_zddy._jlzzr': 'De populairste items van de afgelopen twee weken',
  '_zddy._ljgm': 'Koop nu',
  '_zddy._gdsp': 'Meer populaire producten',
  '_zddy._ssbt': 'Zoeken',
  '_zddy._ssgjz': 'Ik ben op zoek naar..',

  '_zddy._bdhb': 'Lokale valuta',
  '_zddy._xzbdhb': 'Selecteer lokale valuta',
  '_zddy._zje': 'Totaalbedrag',
  '_zddy._xzzffs': 'Kies de betaalmethode die bij u past',
  '_zddy._jmhb': 'Cryptogeld',

  '_zddy._cp': 'product',
  '_zddy._sj': 'Zakenman',
  '_zddy._mj': 'Verkoper',
  '_zddy._fhsy': 'Terug naar startpagina',

  '_sjsy._ddpl': 'Bestellijst',
  '_yhqd._czbz': 'Neem contact op met de toegewijde klantenservice om de oplaadinformatie te bevestigen voordat u gaat opladen.',
  '_yhqd._lxkf': 'Contacteer de Klantenservice',
  '_yhqd._qsrje': 'Voer het opwaardeerbedrag in',
  '_yhqd._wrhyhtd': 'Geen toegang tot de bank',
  '_yhqd._yhzz': 'Overschrijving',
  '_yhbt._yhlx': 'Banktype',
  '_zcyz._sjdxyz': 'Mobiele sms-verificatie',
  '_zcyz._sjhm': 'telefoonnummer',
  '_zcyz._srsjhm': 'Voer mobiel nummer in',
  '_zzsd._qsrzzje': 'Vul het overboekingsbedrag in',
  '_zzsd._zzje': 'bedrag overmaken',
  '_txcd._qsrtxje': 'Voer het opnamebedrag in',
  '_txcd._skr': 'Begunstigde',
  '_txcd._skr._ms': 'Voer de naam van de kaarthouder in',
  '_txcd._skzh': 'Betaalrekeningnummer',
  '_txcd._skzh._ms': 'Vul het betaalrekeningnummer in',
  '_txcd._tjyhk': 'Bankkaart toevoegen',
  '_txcd._txje': 'Opnamebedrag',
  '_txcd._wdyhdz': 'bank adres',
  '_txcd._yhdm': 'code',
  '_txcd._yhdm._ms': 'Voer de bankcode in',
  '_txcd._yhdz': 'adres',
  '_txcd._yhdz._ms': 'Voer het bankadres in',
  '_txcd._yhlx': 'type',
  '_txcd._yhlx._ms': 'Voer het type bankkaart in',
  '_txcd._yhly': 'routering',
  '_txcd._yhly._ms': 'Voer de bankroutering in',
  '_txcd._yhmc': 'bank',
  '_txcd._yhmc._ms': 'Voer de banknaam in',
  '_txcd._yhtx': 'Geldopname bij de bank',

  '_jys._gmjmhb': 'Koop cryptocurrency',
  '_jys._gmzy': 'Je kunt een account registreren en cryptocurrency kopen door naar de volgende beurzen te gaan en volgens het beleid van je huidige land. Laad dan op naar het platformaccount! ',

  '_kdydj._bt': 'Open een winkel en win grote prijzen',
  '_kdydj._xyzbt': 'Het geluk blijft keren',
  '_kdydj._yx': 'Pomp {n} keer',
  '_kdydj._xh': 'Verbruik {n} punten',

  '_kdydj._jf': 'Punten:',
  '_kdydj._zjjl': 'Winnend record',

  '_kdydj._hdgz': 'Activiteitsregels',
  '_kdydj._qx': 'Alsjeblieft',
  '_kdydj._dl': 'Inloggen',

  '_kdydj._mjdl': 'Aanmelding verkoper',
  '_kdydj._yxh': 'E-mail',
  '_kdydj._yxhsm': 'Voer uw e-mailadres in',
  '_kdydj._yxhmm': 'Voer wachtwoord in',
  '_kdydj._rgmyzh': 'Als je geen account hebt',
  '_kdydj._djzc': 'Klik om te registreren',
  '_kdydj._wjmm': 'Wachtwoord vergeten',

  '_kdydj._wdjp': 'Mijn prijs',
  '_kdydj._zjts': 'Winnende tips',
  '_kdydj._jx': 'Doorgaan',
  '_kdydj._czl': 'Gewonnen',

  '_kdydj._ljjl': 'Cumulatieve beloning',
  '_kdydj._ycy': 'Deelgenomen: {n} keer',
  '_kdydj._cy': 'Neem {n} keer deel',
  '_kdydj._lq': 'Ontvangen',
  '_kdydj._ylq': 'Ontvangen',
  '_kdydj._wdd': 'Niet bereikt',

  '_dk._dkbt1': 'Ondernemerslening lost het probleem van het lenen van geld op',
  '_dk._dkbt2': 'Verstrek ondernemersleningen en kapitaalcirculatiediensten',
  '_dk._dkbt3': 'Wij helpen u bij het oplossen van leenproblemen, zoals krappe financiële middelen, nergens geld lenen en u schamen om vrienden te vragen om geld te lenen. Wij bieden professionele diensten gedurende het hele proces en beantwoorden uw vragen op elk gewenst moment Het doel is om elke lening transparant te maken, zodat klanten er snel en met vertrouwen gebruik van kunnen maken. ',
  '_dk._zxsq': 'Online-applicatie',
  '_dk._wddk': 'Mijn lening',
  '_dk._aqwy': 'Veiligheid en zorgeloos',
  '_dk._lhed': 'Flexibel quotum',
  '_dk._lhedb': 'Bedrag: 5,00 tot 500.000, lage rente, flexibele terugbetalingsmethoden, geen kosten vooraf, online lenen, geen beoordelingsgesprekken, vertrouwelijkheid van informatie en lenen op dezelfde dag. ',
  '_dk._jsfk': 'Snelle lening',
  '_dk._jsfkb': 'Slaag de beoordeling binnen het snelste uur en de betaling wordt onmiddellijk na de beoordeling uitgevoerd. ',
  '_dk._aqww1': 'Veilig en zorgeloos',
  '_dk._aqww1b': 'Geen hypotheeklening, geen aanbetaling vereist, geen terugbellen, dit is een privéaangelegenheid. ',

  '_dk._gywm': 'Over ons',
  '_dk._gywm1': "Wij zetten ons in voor het verlenen van financiële diensten, gebaseerd op de bedrijfsfilosofie van 'de klant eerst' en met de missie om de kapitaalomzetproblemen van klanten op te lossen. Sinds de oprichting heeft het bedrijf talloze klanten geholpen bij het oplossen van kapitaalomzetproblemen problemen. ",
  '_dk._gywm2': 'Bedrag: 500 tot 500.000, lage rente, flexibele terugbetalingsmethoden, geen kosten vooraf, online lenen, geen beoordelingsgesprekken, vertrouwelijkheid van informatie en lenen op dezelfde dag. ',
  '_dk._gywm3': 'Als je geen geld wilt lenen van klasgenoten en vrienden, kom dan naar ons toe. ',
  '_dk._gywm4': 'Toegewijd aan leendiensten voor ondernemers',
  '_dk._gywm5': 'Los uw financiële problemen op',
  '_dk._hzhb': 'Partner - Online leenplatform',
  '_dk._smwda': 'Gebruikers van het shopifyoutlets Business-wereldstation komen uit 112 landen over de hele wereld en gebruiken cryptocurrency USDT/ETH/BTC/TRX voor afwikkeling. Cryptocurrency is een manier van handelen zonder grenzen, waardoor directe, goedkope transacties over de hele wereld mogelijk zijn, zonder wachttijden en zonder internationale kosten. ',

  '_dk._yjwa': 'shopifyoutlets Business© Co., Ltd. 2022-2024 Alle rechten voorbehouden',
  '_dk._yjwab': 'ShopifyOutlets Holdings Global Sourcing Limited (0008945) is geautoriseerd en gereguleerd door de Financial Conduct Authority als kredietmakelaar en werkt uitsluitend samen met de kredietverstrekker Home Retail Group CardServices Limited, handelend onder de namen shopifyoutlets Business Financial Services, shopifyoutlets Business Financial Services zijn geautoriseerd en gereguleerd door de Financial Conduct Authority om krediet te verstrekken. Home Retail GroupCard Services Limited is geregistreerd in Engeland (04007072), Avebury, 489-499Avebury Boulevard, Milton Keynes, Verenigd Koninkrijk. MK9 2NW. ',

  '_dk._cyzjsq': 'Aanvraag zakelijke lening invullen',
  '_dk._dkll': 'Leningsrente',
  '_dk._dkll2': 'Beschrijving van leningrente (klik om weer te geven)',
  '_dk._gs1': 'Formule',
  '_dk._gs2': 'Interesse',
  '_dk._gs3': 'Leenbedrag',
  '_dk._gs4': 'Jaarlijkse rente',
  '_dk._gs5': 'Leenaantal dagen',
  '_dk._gs6': 'bijvoorbeeld',
  '_dk._gs7': 'Leenbedrag',
  '_dk._gs8': 'Leningscyclus',
  '_dk._gs9': 'dag',
  '_dk._fkfs': 'Uitleenmethode',
  '_dk._zhye': 'Accountsaldo',
  '_dk._dkqx': 'Uitleenperiode',
  '_dk._dkqx1': 'Selecteer uitleenperiode',
  '_dk._sqje': 'Aanvraagbedrag',
  '_dk._qsrdk': 'Voer een lening in',
  '_dk._dkje': 'Leenbedrag',
  '_dk._gj': 'Nationaliteit',
  '_dk._qxzgj': 'Selecteer nationaliteit',
  '_dk._sqr': 'Aanvrager',
  '_dk._qsrsqsm': 'Voer de naam van de aanvrager in',
  '_dk._zjhm': 'ID-nummer',
  '_dk._qsrzjhm': 'Voer het ID-nummer in',
  '_dk._zjzp': 'ID-foto',
  '_dk._zjzp1': 'Upload een foto van de voorkant van uw identiteitsbewijs',
  '_dk._zjfm1': 'Upload een foto van de achterkant van uw identiteitsbewijs',
  '_dk._zjsc1': 'Upload een foto van uw identiteitskaart',
  '_dk._zjzp2': 'Selecteer afbeelding',
  '_dk._pzslt': 'Fotografievoorbeeld',
  '_dk._wytyb': 'Ik heb het gelezen en ga ermee akkoord',
  '_dk._jkxy': 'Leenovereenkomst',
  '_dk._tjsq': 'Aanvraag indienen',

  '_dk._wddk2': 'Mijn lening',
  '_dk._ydk': 'Uitgeleend',
  '_dk._cslx': 'Interesse genereren',
  '_dk._yjhk': 'Geschatte terugbetaling',
  '_dk._dkjl': 'Leningsrecord',
  '_dk._hkjl': 'Terugbetalingsrecord',
  '_dk._sqrq': 'Aanvraagdatum',
  '_dk._hkzq': 'Aflossingscyclus',
  '_dk._gdll': 'Vaste rente',
  '_dk._hkz': 'Terugbetaling in behandeling',
  '_dk._yhk': 'Terugbetaald',
  '_dk._sqz': 'Toepassen',
  '_dk._ytg': 'Geslaagd',
  '_dk._ybh': 'Afgewezen',
  '_dk._tqhk': 'Vervroegde aflossing',

  '_dk._ljhk': 'Onmiddellijk terugbetalen',
  '_dk._qrytqhk': 'Weet u zeker dat u vooraf terugbetaling wilt aanvragen?',
  '_dk._qrytqhk1': 'Let op: nadat de aanvraag succesvol is, wordt het gestorte bedrag direct met voorrang teruggestort op de lening',
  '_dk._dkxq': 'Leninggegevens',
  '_dk._dkje1': 'Leenbedrag',
  '_dk._yhlx': 'Verschuldigde rente',
  '_dk._hkze': 'Totale terugbetaling',
  '_dk._sqhkz': 'Terugbetaling aanvragen',
  '_dk._zxydbty': 'Lees dit aandachtig door en vink het vakje aan om akkoord te gaan met de "Leningsovereenkomst"',
  '_dk._zzsqz': 'Toepassen',

  '_dk._dksq': 'Krediet',

  '_dk._cs': 'Parameters',
  '_dk._nllsm': 'Uitgedrukt als een percentage, bijvoorbeeld: 6,25%',
  '_dk._dktssm': 'Het totale aantal dagen vanaf het begin tot het einde van de goedkeuring van de lening',
  '_dk._yndts': 'Het aantal dagen in een jaar: meestal 365 dagen',
  '_dk._zlj': 'Te late vergoeding',

  '_xbb._xz': 'Downloaden',
  '_xbb._xzapp': 'ShopifyOutlets-app downloaden',
  '_xbb._smewm': 'Scan de QR-code om te downloaden',
  '_xbb._syfl': 'Alle categorieën',
  '_xbb._gdfl': 'Meer',
  '_xbb._sbbt1': 'Snelle levering',
  '_xbb._sbbt2': 'Restituties zijn beschikbaar als er problemen zijn',
  '_xbb._sbbt3': 'Gratis retourneren',
  '_xbb._sbbt4': 'Binnen 90 dagen',
  '_xbb._tjsp1': 'Aanbevolen producten',
  '_xbb._tjsp2': 'Veel kortingen',
  '_xbb._tjsp3': 'Beperkte tijdskorting',

  '_xbb._asjgw': 'Winkelen per winkel',
  '_xbb._gdsp': 'Meer producten',
  '_xbb._ljgm': 'Koop nu',
  '_xbb._ptbz': 'ShopifyOutlets-garantie',
  '_xbb._aqzf': 'Veilige betaling',
  '_xbb._aqzf1': 'Een betaalmethode die door veel internationale shoppers wordt gebruikt',
  '_xbb._aqys': 'Beveiliging en privacy',
  '_xbb._aqys1': 'Wij respecteren uw privacy, dus uw persoonlijke gegevens zijn veilig',
  '_xbb._mjbh': 'Kopersbescherming',
  '_xbb._mjbh1': 'Als uw bestelling niet vóór de verwachte datum wordt geleverd of als u niet tevreden bent met de bestelling, kunt u uw geld terugkrijgen',
  '_xbb._mzsm': 'Waarschuwing/disclaimer',

  '_xbb._sjxx': 'Zakenman',
  '_xbb._ggms': 'Specificatie',
  '_xbb._mswa': 'Beschrijving',
  '_xbb._khpl': 'Opmerkingen van klanten',
  '_xbb._zmfk': 'Positieve feedback',
  '_xbb._gzrs': 'Volg',
  '_xbb._gzrsan': 'Volg',
  '_xbb._ygzrsan': 'Al gevolgd',
  '_xbb._fsxx': 'Bericht',
  '_xbb._myrpl': 'Nog geen reacties',
  '_xbb._llgd': 'Meer bekijken',
  '_xbb._pdcl': 'Beloof',
  '_xbb._myf': 'Gratis verzending',
  '_xbb._sdsj': 'Geleverd in ongeveer 5-10',
  '_xbb._ksjh': 'Snelle levering',
  '_xbb._bgdstk': 'Terugbetaling voor verloren pakket',
  '_xbb._shbgtk': 'Als het artikel beschadigd is, restitutie',
  '_xbb._s30gtk': 'Terugbetaling indien niet binnen 30 dagen verzonden',
  '_xbb._aqyys': 'Beveiliging en privacy',
  '_xbb._wjtrxk': 'Veilige betaling: we delen uw persoonlijke gegevens niet met derden zonder uw toestemming. ',
  '_xbb._sfgrxx': 'Beveilig persoonlijke gegevens: wij beschermen uw privacy en houden uw persoonlijke gegevens veilig. ',
  '_xbb._sl': 'Aantal',
  '_xbb._kcld': 'Inventaris',

  '_xbb._sjrz': 'Handelaar vestigde zich',
  '_xbb._hezjh': 'Ons partnerprogramma biedt u een volledig scala aan marketingondersteuning en -diensten, en ons klantenserviceteam biedt professionele ondersteuning en advies om u te helpen uw productpresentatie en verkoopstrategie te optimaliseren. Sluit je nu bij ons aan! Laten we grotere zakelijke kansen creëren en samen groeien! ',
  '_xbb._xsje': 'Bedrijfsinformatie',
  '_xbb._rgsqjs': 'Als u al een verkoper bent',
  '_xbb._qni': 'Alsjeblieft',
  '_xbb._djdl': 'Klik om in te loggen',
  '_xbb._dplog': 'Logo opslaan',
  '_xbb._dpmc': 'Winkelnaam',
  '_xbb._srdpmc': 'Voer de winkelnaam in',
  '_xbb._zjhm': 'ID-nummer',
  '_xbb._srzjhm': 'Voer het ID-nummer in',
  '_xbb._zsxm': 'echte naam',
  '_xbb._srzsxm': 'Voer alstublieft uw echte naam in',
  '_xbb._srgj': 'Land',
  '_xbb._srsrgj': 'Voer het land in',
  '_xbb._zjz': 'ID-foto',
  '_xbb._zjzm': 'Voorkant van het document',
  '_xbb._zjfm': 'Achterkant van certificaat',
  '_xbb._sczjz': 'ID-foto vasthouden',
  '_xbb._pssl': 'Fotografievoorbeeld',
  '_xbb._dlyx': 'Inlog-e-mailadres',
  '_xbb._srdlyx': 'Voer uw login-e-mailadres in',
  '_xbb._dlmm': 'Inlogwachtwoord',
  '_xbb._srdlmm': 'Voer uw aanmeldingswachtwoord in',
  '_xbb._qrmm': 'Bevestig wachtwoord',
  '_xbb._srqrmm': 'Voer het bevestigingswachtwoord in',
  '_xbb._yqm': 'uitnodigingscode',
  '_xbb._sryqm': 'Voer de uitnodigingscode in',
  '_xbb._yxyzm': 'E-mailverificatiecode',
  '_xbb._sryzm': 'Voer e-mailverificatiecode in',
  '_xbb._yydbty': 'Ik heb het gelezen en ga ermee akkoord',
  '_xbb._rzxy': 'Schikkingsovereenkomst',

  '_xbb._qgxty': 'Lees de schikkingsovereenkomst zorgvuldig en vink het vakje aan om akkoord te gaan',
  '_xbb._sryqmb': 'Voer de uitnodigingscode in',

  '_xbb._ddpl': 'Bestelopmerkingen',
  '_xbb._dsppl': 'Opmerkingen over producten',
  '_xbb._dddpl': 'Reageer op de bestelling',

  '_xhd._sdj': 'Kerstmis'
}

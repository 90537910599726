<template>
  <a-table :columns="columns" :data-source="dataSource" :pagination="pagination" :loading="loading" @change="getStoreIncomeLog">
    <template #bodyCell="{ column, record }">
      <template v-if="record && column.dataIndex === 'type'">
        <a-tag color="" v-if="record.type == 1">Buy</a-tag>
        <a-tag color="" v-if="record.type == 2">Deposit</a-tag>
        <a-tag color="" v-if="record.type == 3">Withdraw</a-tag>
        <a-tag color="" v-if="record.type == 4">Profit</a-tag>
        <a-tag color="" v-if="record.type == 5">Purchase</a-tag>
        <a-tag color="red" v-if="record.type == 6">Rejected (Withdrawal)</a-tag>
        <a-tag color="green" v-if="record.type == 7">Success (Withdrawal)</a-tag>
      </template>
      <template v-if="record && column.dataIndex === 'profit'">
        <a-tag color="green">+ ${{ (parseFloat(record.profit) + parseFloat(record.dividends) + parseFloat(record.task_profit)).toFixed(2) }}</a-tag>
      </template>
      <template v-if="record && column.dataIndex === 'number'"> </template>
      <template v-if="record && column.dataIndex === 'status'">
        <a-tag color="orange" v-if="record.status == 1">review</a-tag>
        <a-tag color="green" v-if="record.status == 2">success</a-tag>
        <a-tag color="red" v-if="record.status == 3">reject</a-tag>
      </template>
    </template>
  </a-table>
</template>

<script>
// const TYPE = {
//   1: "采购",
//   2: "收益",
// };
export default {
  name: 'TransferList',
  data() {
    return {
      //存币记录表格
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          // sorter: true,
          width: '100px'
        },
        {
          title: this.$t('income.create.at'),
          dataIndex: 'create_time'
        },
        {
          title: this.$t('income.order.sn'),
          dataIndex: 'order_sn'
        },
        {
          title: this.$t('income.realpay'),
          dataIndex: 'real_pay',
          customRender: (text) => {
            return '$' + text.value
          }
        },
        {
          title: this.$t('income.profit'),
          dataIndex: 'profit'
        }
      ],
      loading: false,
      pagination: { total: 0, current: 1, pageSize: 10 },
      dataSource: [],
      queryParam: {
        search: ''
      }
    }
  },
  created() {
    this.getStoreIncomeLog(this.pagination)
  },
  methods: {
    refresh() {
      this.getStoreIncomeLog({ total: 0, current: 1, pageSize: 10 })
    },
    getStoreIncomeLog(parameter) {
      const pagination = {
        page: parameter.current,
        size: parameter.pageSize
      }
      const requestParameters = Object.assign({}, pagination, this.queryParam)

      this.loading = true

      let that = this
      this.$api.store.getStoreIncomeLog(requestParameters).then((res) => {
        that.loading = false
        that.pagination = {
          total: res.data.total,
          current: res.data.pageNo,
          pageSize: res.data.pageSize
        }
        that.dataSource = res.data.data
      })
    }
  }
}
</script>

<style></style>

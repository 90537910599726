<template>
  <a-table :columns="columns" :data-source="dataSource" :pagination="pagination" :loading="loading" @change="getStoreReceive" style="width: 100%">
    <template #bodyCell="{ column, record }">
      <!-- <template v-if="record && column.title === 'Name'">
        <div style="display:flex;align-items: center;">
          <img :src="record.img" width="40" style="margin-right:5px" />
          <div style="font-size:13px">{{record.name}}</div>
        </div>
      </template>
      <template v-if="record && column.title === 'Price'">
        <div>${{record.price}}</div>
      </template>-->
      <template v-if="record && column.key === 'status'">
        <a-tag :color="statusType(record.status).color">{{ statusType(record.status).text }}</a-tag>
      </template>
    </template>
  </a-table>
</template>

<script>
export default {
  name: 'ReceiveList',
  data() {
    return {
      //存币记录表格
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          // sorter: true,
          width: '100px'
        },
        // {
        //   title: "交易单号",
        //   dataIndex: "trade_id",
        // },
        {
          title: this.$t('recive.method'),
          customRender: (item) => {
            return item.record.name + ' (' + item.record.cp_name + ')'
          }
        },
        {
          title: this.$t('recive.amount'),
          dataIndex: 'real_number'
        },
        {
          title: this.$t('recive.address'),
          dataIndex: 'address'
        },
        {
          title: this.$t('bank.list.status'),
          key: 'status'
        },
        {
          title: this.$t('recive.date'),
          dataIndex: 'create_time'
        }
      ],
      status: {
        1: {
          text: this.$t('bank.list.status1'),
          color: 'orange'
        },
        2: {
          text: this.$t('bank.list.status2'),
          color: 'green'
        },
        3: {
          text: this.$t('bank.list.status3'),
          color: 'red'
        }
      },
      loading: false,
      pagination: { total: 0, current: 1, pageSize: 10 },
      dataSource: [],
      queryParam: {
        search: ''
      }
    }
  },
  created() {
    this.getStoreReceive(this.pagination)
  },
  methods: {
    refresh() {
      this.getStoreReceive({ total: 0, current: 1, pageSize: 10 })
    },
    statusType(status) {
      return this.status[status]
    },
    getStoreReceive(parameter) {
      const pagination = {
        page: parameter.current,
        size: parameter.pageSize
      }
      const requestParameters = Object.assign({}, pagination, this.queryParam)

      this.loading = true

      let that = this
      this.$api.store.getStoreReceive(requestParameters).then((res) => {
        that.loading = false
        that.pagination = {
          total: res.data.total,
          current: res.data.pageNo,
          pageSize: res.data.pageSize
        }
        that.dataSource = res.data.data
      })
    }
  }
}
</script>

<style></style>

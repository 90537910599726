<template>
  <a-table :columns="columns" :data-source="dataSource" :pagination="pagination" :loading="loading" @change="getStoreWalletlog">
    <template #bodyCell="{ column, record }">
      <template v-if="record && column.dataIndex === 'type'">
        <a-tag color="red" v-if="record.type_str.fh == '-'">{{record.type_str.bt}}</a-tag>
        <a-tag color="green" v-if="record.type_str.fh == '+'">{{record.type_str.bt}}</a-tag>
        <a-tag color="" v-if="record.type_str.fh == ''">{{record.type_str.bt}}</a-tag>
        <!-- <a-tag color="" v-if="record.type == 2">Deposit</a-tag>
        <a-tag color="" v-if="record.type == 3">Withdraw</a-tag>
        <a-tag color="" v-if="record.type == 4">Profit</a-tag>
        <a-tag color="" v-if="record.type == 5">Purchase</a-tag>
        <a-tag color="red" v-if="record.type == 6">Rejected (Withdrawal)</a-tag>
        <a-tag color="green" v-if="record.type == 7">Success (Withdrawal)</a-tag>
        <a-tag color="" v-if="record.type == 8">Refund</a-tag>
        <a-tag color="" v-if="record.type == 11">Transfer</a-tag>
        <a-tag color="" v-if="record.type == 12">Traffic Promotion</a-tag>
        <a-tag color="" v-if="record.type == 13">Rent</a-tag>
        <a-tag color="" v-if="record.type == 14">Penalty</a-tag>
        <a-tag color="" v-if="record.type == 15">Tax</a-tag> -->
      </template>
      <template v-if="record && column.dataIndex === 'number'">
        <a-tag color="red" v-if="record.type_str.fh == '-'">-{{record.number}}</a-tag>
        <a-tag color="green" v-if="record.type_str.fh == '+'">+{{record.number}}</a-tag>
        <a-tag color="" v-if="record.type_str.fh == ''">{{record.number}}</a-tag>
        <!-- <a-tag color="green" v-if="record.type == 2">+ ${{ record.number }}</a-tag>
        <a-tag color="red" v-if="record.type == 3">- ${{ record.number }}</a-tag>
        <a-tag color="green" v-if="record.type == 4">+ ${{ record.number }}</a-tag>
        <a-tag color="red" v-if="record.type == 5">- ${{ record.number }}</a-tag>
        <a-tag color="green" v-if="record.type == 6">+ ${{ record.number }}</a-tag>
        <a-tag color="red" v-if="record.type == 7">- ${{ record.number }}</a-tag>
        <a-tag color="green" v-if="record.type == 8">+ ${{ record.number }}</a-tag>
        <a-tag color="green" v-if="record.type == 11">+ ${{ record.number }}</a-tag>
        <a-tag color="red" v-if="record.type == 12">- ${{ record.number }}</a-tag>
        <a-tag color="red" v-if="record.type == 13">- ${{ record.number }}</a-tag>
        <a-tag color="red" v-if="record.type == 14">- ${{ record.number }}</a-tag>
        <a-tag color="red" v-if="record.type == 15">- ${{ record.number }}</a-tag> -->
      </template>
    </template>
  </a-table>
</template>

<script>
// const TYPE = {
//   1: "采购",
//   2: "收益",
// };

export default {
  name: 'TransferList',
  data() {
    return {
      //存币记录表格
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          // sorter: true,
          width: '100px'
        },
        {
          title: this.$t('recive.create.at'),
          dataIndex: 'create_time'
        },
        {
          title: this.$t('recive.type'),
          dataIndex: 'type'
        },
        {
          title: this.$t('recive.befor'),
          dataIndex: 'befor',
          customRender: (text) => {
            return '$' + text.value
          }
        },
        {
          title: this.$t('recive.amount'),
          dataIndex: 'number'
        },
        {
          title: this.$t('recive.balance'),
          dataIndex: 'after',
          customRender: (text) => {
            return '$' + text.value
          }
        },
        {
          title: this.$t('recive.freeze'),
          dataIndex: 'freeze',
          customRender: (text) => {
            return '$' + text.value
          }
        }
      ],
      loading: false,
      pagination: { total: 0, current: 1, pageSize: 10 },
      dataSource: [],
      queryParam: {
        search: ''
      }
    }
  },
  created() {
    this.getStoreWalletlog(this.pagination)
  },
  methods: {
    refresh() {
      this.getStoreWalletlog({ total: 0, current: 1, pageSize: 10 })
    },
    getStoreWalletlog(parameter) {
      const pagination = {
        page: parameter.current,
        size: parameter.pageSize
      }
      const requestParameters = Object.assign({}, pagination, this.queryParam)

      this.loading = true

      let that = this
      this.$api.store.getStoreWalletlog(requestParameters).then((res) => {
        that.loading = false
        that.pagination = {
          total: res.data.total,
          current: res.data.pageNo,
          pageSize: res.data.pageSize
        }
        that.dataSource = res.data.data
      })
    }
  }
}
</script>

<style></style>

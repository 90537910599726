<template>
  <div class="slick-track">
    <swiper
      :modules="modules"
      :slidesPerView="1"
      :loop="true"
      :autoplay="{
        delay: 50000,
        disableOnInteraction: false
      }"
    >
      <swiper-slide>
        <div :style="'width:100%;height:70px;background-color: rgb(47, 7, 7); background-image: url(' + require('@/static/css5/cate/img1.gif') + '), url(' + require('@/static/css5/cate/img2.webp') + ')'">
          <section class="new-user--centerBox--uI47511" style="height: 100%; width: 100%">
            <h1 class="new-user--title--KyH7dHQ sdesa" style="color: rgb(255, 255, 255); height: 100%">Christmas Crazy Sale</h1>
            <div style="position: absolute; top: 30%; right: 10%; display: flex; gap: 20px">
              <router-link style="color: #d1d1d1; font-weight: 700" :to="{ name: 'storeDocs', query: { key: 'christmas' } }"> {{ $t('_wsj._gz') }} </router-link>
              <router-link style="color: #fff; font-weight: 700" :to="{ name: 'StoreAddFromHouse', query: { search: 'christmas' } }"> {{ $t('_wsj._cj') }} </router-link>
            </div>
          </section>
        </div>
      </swiper-slide>
      <swiper-slide v-for="(item, i) in ImgList" :key="i">
        <div style="position: relative">
          <div style="position: absolute; top: 30%; right: 10%; display: flex; gap: 20px">
            <router-link style="color: #d1d1d1; font-weight: 700" :to="{ name: 'storeDocs', query: { key: item.key } }"> {{ $t('_wsj._gz') }} </router-link>
            <router-link style="color: #fff; font-weight: 700" :to="{ name: 'StoreAddFromHouse', query: { search: item.key } }"> {{ $t('_wsj._cj') }} </router-link>
          </div>
          <img :src="item.url" alt="" style="width: 100%" />
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import { Navigation, Pagination, Autoplay } from 'swiper'
const modules = [Navigation, Pagination, Autoplay]

const ImgList = ref([
  {
    id: 1,
    key: 'halloween',
    url: require('@/static/css5/halloween/120.png')
  }
  // {
  //   id: 2,
  //   key: 'christmas',
  //   url: require('@/static/img2/121.png')
  // }
])
</script>
<style scoped>
.sdesa {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}
</style>

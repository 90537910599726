export default {
  'nav.account': 'Centro personal',
  'nav.language': 'Chino simplificado',
  'signup.success': 'Registro exitoso',

  //error
  'contact.empty': 'La información de contacto no puede estar vacía',
  'phone.country.empty': 'El prefijo del teléfono no puede estar vacío',
  'phone.empty': 'El teléfono no puede estar vacío',
  'email.valid': 'Ingrese el correo electrónico correcto',

  // base
  'base.éxito': 'éxito',
  'base.save': 'Guardar',
  'base.slide': 'Por favor, tire del control deslizante para verificar',
  'base.send.code': 'Enviar código de verificación',
  'base.send.time': '({time}) reenviar código de verificación',
  'base.send.success': 'Enviar con éxito',
  'base.view.more': 'Ver más',
  'base.Idioma': 'Idioma',
  'base.previous': 'Página anterior',
  'base.next': 'Página siguiente',
  'base.total.page': 'Total de {página} páginas',
  'base.go.to.page': 'Ir a la página',
  'base.go': 'ir a',
  'base.followers': 'Seguidores',
  'base.seguir': 'seguir',
  'base.following': 'Seguido',
  'base.visit.store': 'Buscar tienda',
  'base.contact': 'Contáctenos',
  'base.delivery': 'Tiempo de entrega estimado: 1-7 días',
  'base.search': 'Buscar',
  'base.search.text': 'Ingresar búsqueda por palabra clave',
  'base.server': 'Servicio al cliente en línea',

  //índice
  'text.categories.title': 'Categoría de producto',
  'text.welcome': '¡Bienvenido a comprar!',
  'text.wishlist': 'Lista de Me gusta',
  'text.account': 'Centro personal',
  'text.orders': 'Pedidos',
  'text.messages': 'Mensajes',
  'text.suggest.title': 'Oferta exclusiva',
  'text.suggest.content': '¡Solo para nuevos miembros premium globales!',
  'text.super.deals': 'Los mejores productos, precios increíbles.',

  //inscribirse
  'signup.registrar': 'Registrarse',
  'signup.signin': 'Iniciar sesión',
  'signup.store.signin': 'Inicio de sesión empresarial',
  'signup.sign.out': 'Cerrar sesión',
  'signup.email': 'Dirección de correo electrónico',
  'signup.password': 'Contraseña',
  'signup.qr.password': 'Confirmar contraseña',
  'signup.confirm.password': 'Confirmar contraseña',
  'signup.forgot.password': 'Olvidé mi contraseña',
  'signup.invitation': 'Código de invitación',
  'signup.char': 'Personaje',
  'signup.contains': 'Contiene números, letras o símbolos',
  'signup.qr.contains': 'Las dos contraseñas son inconsistentes',
  'signup.create.account': 'Crear cuenta',
  'signup.agree': 'Crea una cuenta, estás de acuerdo',
  'signup.member.agreement': 'Acuerdo de Membresía',
  'registrarse.y': 'y',
  'signup.privacy.policy': 'Política de Privacidad',
  'signup.email.code': 'Verificación de correo electrónico',
  'signup.last.step': 'Último paso',
  'signup.send.email': 'Ingrese el código de 4 dígitos enviado a {email}',
  'signup.modify.email': 'Modificar correo electrónico',
  'signup.verify.email': 'Verificar correo electrónico',
  'signup.have.store': 'Tener cuenta de comerciante',
  'signup.goto.signin': 'Inicio de sesión empresarial',
  'signup.no.store': 'Sin cuenta de comerciante',
  'signup.goto.store': 'Registrar Comerciante',
  'signup.next': 'Siguiente',
  'signup.your.email': 'tu correo electrónico',
  'signup.code.text': 'Código de verificación',
  'signup.submit.signup': 'Regístrese ahora',
  'signup.smrz': 'Autenticación de nombre real',
  'signup.derb': 'Segundo paso',
  'signup.qsrxm': 'Ingrese el nombre del documento',
  'signup.qsrhm': 'Ingrese el número de identificación',

  //olvidó
  'forgot.title': 'Restablecer contraseña',
  'forgot.btn.check': 'Verificar correo electrónico',
  'forgot.reset.now': 'Restablecer ahora',

  //almacenar
  'store.info.open': 'Abierto {y} semanas',

  // pie de página
  'footer.great.value': 'Gran valor',
  'footer.great.value.desc': 'Ofrecemos precios competitivos para más de 100 millones de artículos.',
  'footer.shopping': 'Compras globales',
  'footer.shopping.desc': 'Realizamos envíos a más de 200 países y regiones, y nuestro sitio web está disponible en 7 idiomas.',
  'footer.safe.payment': 'Pago seguro',
  'footer.safe.payment.desc': 'Pague con los métodos de pago más populares y seguros del mundo.',
  'footer.shop.with.confidence': 'Compre con confianza',
  'footer.shop.with.confidence.desc': 'Nuestra política de protección del comprador cubre todo el proceso de compra.',
  'footer.help.center': 'Centro de ayuda',
  'footer.help.center.desc': 'Asistencia en todo clima para crear una experiencia de compra sin problemas.',
  'footer.terms.conditions': 'Términos y condiciones',
  'footer.return.policy': 'Política de devolución',
  'footer.support.policy': 'Política de soporte',
  'footer.privacy.policy': 'Política de privacidad',
  'footer.be.seller': 'Conviértete en vendedor',
  'footer.apply.now': 'Solicitar ahora',
  'footer.stay.connected': 'Mantenerse conectado',

  'footer.about.us': 'Acerca de nosotros',
  'footer.about.company': 'Perfil de la empresa',
  'footer.about.video': 'Introducción al video',
  'footer.contact': 'Información de contacto',

  'footer.my.account': 'Mi cuenta',
  'footer.my.logout': 'Cerrar sesión',
  'footer.my.order': 'Historial de pedidos',
  'footer.my.wish': 'Mi lista de deseos',
  'footer.my.join': 'Conviértase en socio miembro',
  'footer.email': 'Correo electrónico',
  'footer.gfemail': 'Correo electrónico oficial',
  'footer.fwemail': 'Correo electrónico de servicio',
  'footer.address': 'Dirección',

  'apply.success': 'Solicitud exitosa',
  'apply.success.desc': 'Aplicado con éxito, vaya a la cuenta de inicio de sesión',

  // categorias
  'category.title': 'Categorías relacionadas',
  'category.all': 'Todas las categorías',

  //detalle
  'detail.store.home': 'Tienda de inicio',
  'detail.sale.items': 'Lista de productos',
  'detail.recommend': 'Recomendar',
  'detalle.pedidos': 'Ventas',
  'detalle.cantidad': 'Cantidad',
  'detalle.piezas.disponibles': 'Disponible',
  'detalle.entrega': 'Entrega',
  'detail.free.shipping': 'Envío gratis',
  'detail.estimated.delivery': 'Llegada estimada',
  'detalle.dias': 'dias',
  'detalle.comprar.ahora': 'Comprar',
  'detail.add.to.cart': 'Agregar al carrito',
  'detail.buyer.protection': 'Protección del Comprador',
  'detail.money.guarantee': 'Garantía de devolución de dinero',
  'detail.refund.desc': 'Obtenga un reembolso completo si el artículo no se corresponde con la descripción o no se entregó',
  'detail.description': 'Descripción del producto',
  'detail.customer.reviews': 'Opiniones de clientes',
  'detail.specifications': 'Especificaciones del producto',
  'detail.top.selling.products': 'Productos más vendidos',
  'detalle.recomendado.para.usted': 'Recomendado para usted',
  'detalle.vendido': 'Ventas',
  'detail.receipt': 'Confirmar recibo',
  'detail.receipt.title': '¿Confirmar la recepción del pedido? ',
  'detail.receipt.content': 'Después de la confirmación, la orden de transacción se completa',
  'detalle.comentario': 'Comentario',
  'detail.refund.title': 'Confirmar solicitud de reembolso',
  'detail.refund.content': 'Después de la confirmación, el pedido solicitará un reembolso',
  'detail.sqtk': 'Solicitar reembolso',

  //confirmar
  'confirm.shipping.address': 'Dirección del destinatario',
  'confirmar.cambio': 'Cambiar',
  'confirm.payment.methods': 'Métodos de pago',
  'confirm.summary': 'Liquidación',
  'confirm.total.item.costs': 'Artículos totales',
  'confirmar.total': 'Total',
  'confirm.checkout': 'Ir a la caja',
  'confirm.place.order': 'Realice un pedido ahora',
  'confirm.pay.now': 'Pagar ahora',
  'confirm.order.desc': "Después de hacer clic en 'Realizar pedido', confirmo que he leído y confirmo",
  'confirm.order.policy': 'Todos los términos y políticas',
  'confirm.payment': 'Global Shopping garantiza la seguridad de su información y pago',

  // DIRECCIÓN
  'address.title': 'Dirección de recibo',
  'dirección.predeterminada': 'predeterminada',
  'dirección.edit': 'Editar',
  'dirección.eliminar': 'Eliminar',
  'address.new': 'Crear una nueva dirección',
  'dirección.contacto': 'Contacto',
  'dirección.dirección': 'Dirección',
  'dirección.teléfono': 'Número de teléfono',
  'address.set.default': 'Establecer predeterminado',
  'dirección.confirmar': 'Confirmar',
  'dirección.cancel': 'Cancelar',
  'address.del.title': '¿Confirmar para eliminar la dirección?',
  'address.del.content': '¿Está seguro de que desea eliminar esta dirección de envío?',

  'método.de.pago': 'Método de pago',

  // Carro de la compra
  'shopping.title': 'Carrito de la compra',
  'shopping.back.buy': 'Volver a compras especiales',
  'shopping.start.shopping': 'Empezar a comprar',

  // miembro
  'member.account': 'Centro personal',
  'member.overview': 'Resumen',
  'member.orders': 'Pedidos',
  'miembro.pago': 'Mi billetera',
  'member.address': 'Dirección de recibo',
  'member.wishlist': 'Lista de Me gusta',
  'member.followlist': 'Lista de seguimiento',
  'member.message.center': 'Centro de mensajes',
  'member.setting': 'Configuración personal',
  'member.shop.info': 'Información de la tienda',
  'member.shop.setting': 'Configuración de la tienda',
  'member.order.notify': 'Notificación de nuevo pedido',
  'member.order.tips': 'Enviando correos electrónicos al correo electrónico de la cuenta del comerciante',

  //miembro.orden
  'member.order.title': 'Mi pedido',
  'member.order.view.all': 'Ver todo',
  'member.order.all': 'Todos',
  'member.order.unpaid': 'no pagado',
  'member.order.paid': 'Pagado',
  'member.order.procurement': 'a comprar',
  'member.order.seller.paid': 'Comprado',
  'member.order.processing': 'para ser procesado',
  'member.order.shipped': 'enviado',
  'member.order.return': 'Volviendo',
  'member.order.completed': 'Completado',
  'member.order.refund': 'Reembolsado',
  'member.order.all.time': 'all',
  'member.order.empty': 'Todavía no hay pedido',
  'miembro.pedido.fecha': 'fecha',
  'member.order.purchase.date': 'Comprar',
  'member.order.cpoy': 'Copiar',
  'member.order.id': 'Pedido',
  'member.order.detail': 'Detalles del pedido',
  'member.order.logistics': 'Información logística',

  // miembro.pago
  'member.payment.title': 'Mi billetera',
  'member.wallet.balance': 'saldo de la billetera',
  'member.crypto.recharge': 'Recargar',
  'member.crypto.withdrawal': 'Retiro',
  'member.crypto.bank': 'Tarjeta bancaria',
  'member.wallet.record': 'registro de depósito de monedas',
  'member.bankcard.record': 'Registro de tarjeta bancaria',
  'member.withdrawal.record': 'Registro de retiro',
  'member.income.record': 'registro de ingresos',
  'member.transaction.record': 'Registro de transacciones',
  'member.wallet.freeze': 'Congelar fondos',
  'member.wallet.profit': 'Ganancias estimadas',

  //recargar
  'recharge.currency': 'Moneda',
  'recharge.protocol': 'Protocolo',
  'recarga.qrcode': 'Código QR',
  'recharge.address': 'Dirección de la billetera',
  'recharge.copy': 'Copiar dirección',
  'recarga.ok.text': 'Confirmar',
  'recargar.cancelar.texto': 'Cancelar',

  //banco
  'bank.recharge.title': 'Recarga de tarjeta bancaria',
  'bank.title': 'Nombre del banco',
  'nombre.banco': 'nombre',
  'cuenta.bancaria': 'Cuenta',
  'bank.routing': 'Enrutamiento',
  'código.banco': 'código',
  'bank.bankname': 'Nombre del banco',
  'bank.bankaddress': 'Dirección del banco',
  'bank.ok.text': 'Siguiente',
  'bank.ok.prev': 'Paso anterior',
  'bank.submit': 'Enviar ahora',
  'bank.amount': 'Importe de la recarga',
  'bank.amount.desc': 'Ingrese el monto de la recarga',
  'banco.tipo': 'tipo',
  'banco.usd': 'USD',
  'banco.eur': 'Euro',
  'bank.receipt.number': 'Número de recibo',
  'bank.receipt.number.desc': 'Ingrese el número de recibo de la transacción',
  'bank.credential.picture': 'Imagen de la credencial',
  'bank.credential.picture.desc': 'Cargue la imagen de la credencial',
  'bank.remark': 'Observación',
  'bank.upload': 'Subir fotos',
  'bank.text.title': 'Billetes',
  'bank.text.t1': 'No necesita ningún comentario para la transferencia, déjelo en blanco',
  'bank.text.t2': 'Seleccione Hong Kong como el país del hogar de Hong Kong, no agregue China',
  'bank.text.t3': 'Remesas de EE. UU., de lunes a viernes, remesas a Europa antes de las 3:00 p. m., ¡las remesas están disponibles durante el horario laboral del banco!',
  'bank.text.t4': 'El tiempo de pago es T+1, y el último pago es T+3 en Europa',
  'bank.text.t5': 'Informe a la plataforma antes de transferir dinero para confirmar si la cuenta está disponible. Si la cuenta se cierra, no se pagará ninguna compensación.',
  'bank.text.t6': 'Depósito mediante tarjeta bancaria, prevalecerá toda la información de servicio al cliente.',

  //lista de bancos
  'bank.list.title': 'Nombre del banco',
  'bank.list.code': 'Código del Banco',
  'bank.list.amount': 'Importe de la transferencia',
  'bank.list.number': 'Número de recibo',
  'bank.list.img': 'Imagen del cupón',
  'bank.list.status': 'Estado',
  'bank.list.time': 'tiempo',
  'bank.list.status1': 'En revisión',
  'bank.list.status2': 'Aprobado',
  'bank.list.status3': 'Rechazado',

  // Retiro
  'withdrawal.address.desc': '¡Ingrese la dirección de la billetera!',
  'número.de.retiro': 'Número',
  'retiro.número.real': 'llegada real',
  'withdrawal.number.desc': '¡Ingrese el monto del retiro!',
  'retiro.btn.all': 'todos',
  'retiro.saldo': 'Saldo',
  'retiro.comisión': 'comisión',
  'retiro.cantidad.real': 'Contabilizado',
  'retiro.aviso': 'Aviso',
  'withdrawal.notice.text': 'Antes de realizar la transferencia, confirme que la información de la dirección de recepción sea correcta. Una vez que se transfieren los activos, no se pueden devolver.',
  'withdrawal.notice.content': '{name}({cp_name}) comisión: valor de mercado actual {fee_rate}%/pen, estándar mínimo: {fee_min} {name}/pen',
  'retiro.enviar': 'Enviar',
  'withdrawal.choice': 'Elija una criptomoneda',
  'retiro.yzm': 'Código de verificación',
  'retiro.fs': 'Enviar',
  'withdrawal.qsryzm': 'Ingrese el código de verificación del correo electrónico',

  // recibir
  'recive.method': 'Protocolo',
  'recibir.cantidad': 'Cantidad',
  'dirección.recibida': 'dirección',
  'recibir.fecha': 'hora',
  'recive.status': 'Estado',
  'recive.create.at': 'Tiempo de transacción',
  'recibir.tipo': 'Tipo',
  'recive.befor': 'antes de la transacción',
  'recibir.saldo': 'Saldo',
  'recive.freeze': 'Congelar',
  'recive.review': 'Revisando',
  'recive.success': 'Aprobado',
  'recibir.rechazar': 'Rechazado',

  // publicidad
  'advertise.title': 'Centro de promoción',
  'advertise.shop.title': 'Promoción de la tienda',
  'advertise.shop.status': 'Estado',
  'advertise.shop.expired': 'Caducado',
  'advertise.shop.promotion': 'Promoción',
  'advertise.shop.expire.date': 'Fecha de vencimiento',
  'advertise.shop.renew': 'Renovación de publicidad',
  'advertise.shop.payable': 'Cantidad a pagar',
  'advertise.shop.explanation': 'Explicación del anuncio',
  'advertise.shop.text': 'Participe en la promoción de publicidad de la plataforma, aumente la exposición de la tienda y promueva pedidos de transacciones',
  'advertise.shop.paynow': 'Renovar ahora',
  'advertise.shop.modal.title': 'Confirmar pago',
  'advertise.shop.modal.desc': 'Confirmado para pagar la tarifa de promoción',
  'advertise.shop.modal.btn': 'Confirmar pago',

  //Lista de deseos
  'lista de deseos.title': 'Lista de deseos',
  'lista de deseos.eliminar': 'Eliminar',
  'lista de deseos.pedidos': 'Ventas',

  //Seguir lista
  'followlist.title': 'Lista de seguimiento',
  'followlist.delete': 'Borrar',
  'followlist.follow': 'Seguir',

  // almacenar
  'store.dashboard': 'Tablero',
  'store.products': 'Productos',
  'store.products.list': 'Lista de productos',
  'store.products.reviews': 'Reseñas de productos',
  'store.orders': 'Pedidos',
  'store.wallet': 'Monedero',
  'store.message': 'Centro de mensajes',
  'store.setting': 'configuración',
  'store.order.total.profit': 'Ingresos operativos totales estimados',

  //panel
  'dashboard.store.hour.views': 'Visitas en tiempo real',
  'dashboard.store.today.views': 'Visitas de hoy',
  'dashboard.product.total': 'Producto total',
  'dashboard.product.today': 'Nuevo hoy',
  'dashboard.order.total': 'Pedido Total',
  'dashboard.sales.total': 'Facturación estimada',
  'dashboard.sales.real': 'Ventas reales',
  'dashboard.sales.pay': 'Pago total',
  'dashboard.sales.profit': 'Beneficio total',
  'dashboard.commission.total': 'Ingresos totales',
  'dashboard.commission.today': 'Ganancias de hoy',
  'dashboard.order.sales': 'Ventas de productos básicos',
  'dashboard.sales.list': 'Clasificación de ventas',
  'dashboard.goods.cate.rate': 'Ratio de clasificación de productos',
  'dashboard.goods.wish': 'Ranking de productos similares',
  'dashboard.january': 'Enero',
  'dashboard.february': 'febrero',
  'dashboard.march': 'Marzo',
  'dashboard.april': 'abril',
  'dashboard.may': 'Mayo',
  'dashboard.june': 'Junio',
  'dashboard.july': 'Julio',
  'dashboard.agosto': 'agosto',
  'dashboard.septiembre': 'Septiembre',
  'dashboard.octubre': 'Octubre',
  'dashboard.noviembre': 'Noviembre',
  'dashboard.diciembre': 'Diciembre',

  // productos
  'products.add.new': 'Agregar nuevo producto',
  'products.add.from.warehouse': 'Agregar productos del almacén',
  'productos.delete': 'Eliminar',
  'productos.add': 'Agregar',
  'productos.table.img': 'imagen',
  'productos.table.name': 'nombre del producto',
  'productos.tabla.categoría': 'Categoría',
  'productos.table.wish': 'me gusta',
  'productos.table.stock': 'stock',
  'productos.tabla.precio': 'Precio',
  'products.table.profit': 'Beneficio',
  'products.table.action': 'Acción',
  'products.search.category': 'Buscar por categoría',
  'products.back.product': 'Volver a la lista de productos',
  'productos.total': 'Bienes totales',
  'productos.yes': 'Sí',
  'productos.no': 'Cancelar',
  'products.batch.add': 'Aumento de lote',
  'products.ask.add': '¿Está seguro de que desea agregar productos?',
  'products.batch.delete': 'Eliminar lote',
  'products.ask.delete': '¿Está seguro de que desea eliminar el producto?',
  'productos.top': 'Arriba',
  'products.syzd': 'Principio de página de inicio',
  'products.zdwz': 'Primera posición',
  'productos.t1': 'Promoción',
  'productos.t2': 'Preferido',
  'productos.t3': 'Tienda',
  'productos.t4': 'ninguno',

  // reseñas
  'reviews.title': 'Reseñas de productos',
  'opiniones.producto.nombre': 'Producto',
  'opiniones.usuario.nombre': 'Usuario',
  'reviews.star': 'Calificación',
  'reviews.comment': 'Contenido del comentario',
  'reviews.sku': 'Especificaciones del producto',
  'revisiones.imgs': 'Atlas',
  'opiniones.creadas': 'Fecha',

  // almacenar.pedir
  'store.order.purchase': 'Comprar ahora',
  'store.order.purchase.desc': '¿Estás seguro de que quieres pagar por este producto?',
  'store.order.purchase.yes': 'Pagar ahora',
  'store.order.purchase.no': 'Cancelar',
  'store.order.desc': 'La compra de este pedido requiere pago, y obtendrá beneficios una vez que se complete el pedido. ',
  'store.order.no': 'Número de pedido',
  'store.order.number': 'Cantidad',
  'store.order.buyer': 'Comprador',
  'store.order.total': 'Cantidad total',
  'store.order.will.earning': 'Ingresos',
  'store.order.profit': 'Beneficio',
  'store.order.dividends': 'Dividendos',
  'store.order.payment.status': 'Estado del pago',
  'store.order.seller.buy.status': 'Estado de compra',
  'store.order.status': 'Estado del pedido',
  'store.order.date': 'fecha del pedido',
  'store.order.purchase.date': 'Fecha de compra',
  'store.order.action': 'Operación',
  'store.order.purchase.amount': 'Importe de la compra',

  //ingreso
  'income.create.at': 'Tiempo récord',
  'ingresos.pedido.sn': 'Número de orden',
  'ingresos.realpay': 'Cantidad del pedido',
  'ingresos.beneficio': 'ingresos',

  //Configuración
  'setting.avatar': 'Avatar empresarial',
  'setting.upload': 'Subir',
  'setting.shop.name': 'Nombre de la tienda',
  'setting.shop.phone': 'Teléfono de contacto',
  'setting.shop.address': 'Dirección de la tienda',
  'setting.shop.save': 'Guardar',
  'setting.upload.pic': 'Subir imagen',
  'setting.send.pic': 'Enviar foto',

  //vendedor
  'beseller.title': 'Aplicar para ser comerciante',
  'beseller.account': 'Información de la cuenta',
  'beseller.store': 'Información de la tienda',
  'beseller.store.name': 'Nombre de la tienda',
  'beseller.store.address': 'Dirección de la tienda',

  // guardar casa
  'store.home.title': 'Configuración de la página de inicio',
  'store.home.topimg': 'imagen superior',
  'store.home.banner': 'Imagen del carrusel',
  'store.home.up3': 'Observaciones: al menos 3 subidas',
  'store.upload.more': 'Subir más imágenes',
  'store.home.columns': 'Columnas',
  'store.home.bgimg': 'Imagen de fondo',
  'store.goods.remark': 'Observación: 5 artículos por línea, 10 artículos por placa como máximo',
  'store.home.select': 'Seleccionar producto',
  'store.home.add': 'Agregar columna',

  //informacion personal
  'setting.update.user': 'Información personal',
  'setting.update.info': 'Modificar información',
  'setting.user.avatar': 'Avatar de usuario',
  'setting.user.nickname': 'User Nickname',
  'setting.user.nickname.desc': 'Ingrese el apodo del usuario',

  'setting.safe.title': 'Información de seguridad',
  'setting.user.passwd': 'Cambiar contraseña',
  'setting.passwd.title1': 'Contraseña anterior',
  'setting.passwd.desc1': 'Ingrese la contraseña anterior',
  'setting.passwd.title2': 'Nueva contraseña',
  'setting.passwd.desc2': 'Ingrese una nueva contraseña',
  'setting.passwd.title3': 'Confirmar contraseña',
  'setting.passwd.desc3': 'Ingrese una contraseña de confirmación',
  'setting.invitation': 'Invitar amigos',
  'setting.copy': 'Copiar',

  'adv.add.products': 'Agregar productos promocionales',
  'adv.list.title': 'Promoción de producto',
  'adv.type': 'Tipo de promoción',
  'adv.begin.time': 'Tiempo de promoción',
  'adv.end.time': 'Hora de finalización',
  'adv.status': 'Estado',
  'adv.status.s1': 'Promoviendo',
  'adv.status.s2': 'Fin',
  'adv.add.back': 'Volver a la lista de promocionados',
  'adv.recharge': 'Recarga puntos promoción',
  'adv.select.goods': 'Seleccionar productos',
  'adv.select.btn': 'Productos Promocionados',
  'adv.recharge.title': 'Recarga Puntos Promoción',
  'adv.recharge.balance': 'Saldo de puntos',
  'adv.punto': 'punto',
  'adv.point.rate': 'Proporción de puntos',
  'adv.recharge.amount': 'Cantidad de recarga',
  'adv.recharge.input.amount': 'Ingrese el monto de la recarga',
  'adv.select.mod': 'Seleccionar panel',
  'adv.mod1': 'Columnas recomendadas',
  'adv.mod2': 'Columna preferida',
  'adv.mod3': 'Columna de venta caliente',
  'adv.mod4': 'Excelente lista',
  'adv.mod5': 'Lista de clasificación',
  'adv.mod6': 'Promoción detallada',
  'adv.ge': 'Cantidad',
  'adv.xs': 'hora',
  'adv.xzbk': 'Seleccionar plato',
  'adv.syme': 'Cuota restante',
  'adv.tgsc': 'Duración de la promoción',
  'adv.tgjg': 'Precio de promoción',
  'adv.syye': 'Saldo actual',
  'adv.tgye': 'Saldo de promoción',
  'adv.yj1': 'esperado',
  'adv.yj2': 'Iniciar promoción',
  'adv.pay.point': 'Puntos de consumo',

  'wallet.ykcsxf': 'La tarifa de manejo ha sido deducida',
  'wallet.sxf': 'Tarifa de servicio',
  'wallet.zxje': 'Cantidad mínima',
  'wallet.24hxe': 'límite de 24 horas',
  'wallet.desc.text': 'Bajo ninguna circunstancia, el oficial no te pedirá que transfieras dinero a una cuenta, ni te pedirá un código de verificación. No participe en actividades ilegales como compras en nombre de otros, lavado de dinero y recaudación de fondos ilegal, y tenga cuidado con el fraude en línea',
  'wallet.cunbi.text': 'Solo puede depositar {nombre} en esta dirección, no se recuperarán otros activos',

  'credit.title': 'Límite de crédito',
  'credit.ed': 'Cuota',
  'credit.hk': 'Reembolso',
  'credit.kyye': 'Saldo disponible',
  'credit.dqqk': 'Atrasos actuales',
  'credit.hkje': 'Cantidad de reembolso',
  'credit.hkje.desc': 'Ingrese el monto del reembolso',
  'credit.cgje': 'Importe de la compra',
  'credit.qrz': 'Ir a autenticación',
  'credit.srxyj': 'Ingrese el monto del pago del crédito',

  'store.gzl': 'Cantidad de atención',
  'store.wxz': 'Ilimitado',

  'auth.smrz': 'Autenticación de nombre real',
  'auth.rzlx': 'Tipo',
  'auth.sfzm': 'Frente de la tarjeta de identificación',
  'auth.sffm': 'El reverso de la tarjeta de identificación',
  'auth.zsxm': 'nombre real',
  'auth.zjhm': 'Número de documento',
  'auth.yyzz': 'Licencia comercial',
  'auth.gsmc': 'Nombre de la empresa',
  'auth.shz': 'Auditoría',
  'auth.ytg': 'Aprobado',
  'auth.ybh': 'Rechazado',
  'auth.zt': 'estado',
  'auth.gr': 'Personal',
  'auth.gs': 'Empresa',
  'auth.ljtj': 'Solicitar autenticación',
  'auth.wrz': 'No autenticado',

  'credit.qts': 'Ver descripción',
  'store.spss': 'Límite superior de productos básicos',
  'store.dpfh': 'Almacenar dividendos',
  'store.qydj': 'Nivel de equidad',
  'tienda.nivel': 'nivel',
  'store.jian': 'piezas',

  'order.notify.email': 'Debido al control de riesgos del correo electrónico, los correos electrónicos de recordatorio de pedidos de la plataforma se envían con frecuencia y serán interceptados. Para recibirlos a tiempo, se recomienda agregar {e} al inicio de sesión lista blanca de correo electrónico',

  'setting.sjxx': 'Información comercial',
  'setting.smrz': 'Autenticación de nombre real',
  'setting.aqzx': 'Centro de Seguridad',
  'setting.tzxx': 'Información de notificación',
  'setting.dpzx': 'Página de inicio de decoración',
  'setting.sjqy': 'Intereses comerciales',
  'setting.sjdjqy': 'Descripción de los beneficios a nivel empresarial',

  'setting.fhbky': 'El dividendo actual no está disponible',
  'setting.spgzyzy': 'Reglas y pautas de la tienda',

  'desc.dqqy': 'Patrimonio actual',
  'desc.xjqy': 'Intereses subordinados',
  'desc.sjqy_0': 'Límite de crédito de la tienda',
  'desc.sjqy_1': 'Almacenar dividendos',
  'desc.sjqy_2': 'Número de productos que se pueden publicar',

  'share.yqlb': 'Lista de invitados',
  'share.zmj': 'Vendedor total',
  'share.zdd': 'Pedido total',
  'share.zsy': 'Ingresos totales por intermediación',
  'share.wcl': 'sin procesar',
  'share.ycl': 'Procesado',
  'share.ywc': 'Completado',
  'share.dpmc': 'Nombre de la tienda',
  'share.ddl': 'Cantidad del pedido',
  'share.jjsy': 'Ingresos de corretaje',
  'share.zts': 'Número total',

  'chat.state': 'estado',
  'chat.zx': 'En línea',
  'chat.ljz': 'Conectando',

  'bind.wallet.title': 'Dirección de retiro',
  'bind.address.title': 'Agregar dirección',
  'bind.bj': 'Editar',
  'bind.sc': 'Eliminar',
  'bind.qrsc': '¿Está seguro de que desea eliminar esta dirección?',
  'bind.qbdz': 'Dirección de billetera',
  'bind.tjsj': 'Agregar hora',
  'bind.cz': 'Operación',
  'withdrawal.address.choice': '¡Por favor elija la dirección de retiro!',

  'order.sxsj': 'Tiempo efectivo',
  'order.ljsj': 'inmediatamente efectivo',
  'order.dssj': 'Tiempo efectivo',

  'store.st.zt': 'Estado de la tienda',
  'tienda.st.zc': 'normal',
  'store.st.zc.desc': 'Tu tienda es normal, sigue así',
  'store.st.yc': 'Excepción',
  'store.st.yc.desc': 'Tu tienda tiene algunos pedidos anormales o recibió quejas de clientes',
  'store.st.dj': 'Congelar',
  'store.st.dj.desc': 'Tu tienda tiene cierto riesgo operativo y los fondos están congelados',
  'store.st.jy': 'deshabilitado',
  'store.st.jy.desc': 'Tu tienda ha violado los acuerdos y reglas relacionados con la plataforma y ha sido deshabilitada',

  'tarea.sy': 'Página de inicio',
  'task.rwlbo': 'Lista de tareas',
  'task.rwxqo': 'Detalles de la tarea',
  'task.ljcy': 'Participa ahora',
  'task.gdrw': 'Más tareas',
  'task.rwlb': 'Lista de tareas de captura',
  'task.qbrw': 'Todas las tareas',
  'task.wcyd': 'Yo participé en',
  'task.qdz': 'Recibiendo pedidos',
  'tarea.ywc': 'Completado',
  'task.djy': 'a negociar',
  'task.ddje': 'Cantidad del pedido',
  'task.cyrs': 'Número de participantes',
  'task.ewsy': 'Beneficios adicionales',
  'tarea.jzsj': 'Fecha límite',
  'task.ckxq': 'Ver detalles',
  'task.rwxq': 'Detalles de la tarea',
  'task.cylb': 'Lista de participantes',
  'task.qdcyrw': '¿Está seguro de que desea participar en esta tarea de pedido?',
  'tarea.sd': 'Sí',
  'tarea.bue': 'no',

  'tarea.wdsy': 'Ingresos',
  'task.wdfh': 'Dividendos',
  'task.wdqd': 'Obtener pedido',
  'task.dd.leixin': 'Tipo de orden',
  'task.dd.qdrw': 'Tarea de pedido rápido',
  'task.dd.ptdd': 'Orden ordinario',

  'flow.lltg': 'Promoción de flujo',
  'flow.gmll': 'Paquete de datos de compra',
  'flow.dqtc': 'Paquete actual',
  'flow.tc1': 'Paquete',
  'flujo.sy2': 'restante',
  'flujo.tian3': 'día',
  'flow.jlzs': 'Número total de registros',
  'flow.lltc': 'Paquete de flujo',
  'flujo.ll': 'flujo',
  'flow.gmsj': 'Tiempo de compra',
  'flow.tcjg': 'Precio del paquete',
  'flow.sjzf': 'pago real',
  'flow.tcsm': 'Descripción del paquete',
  'flow.tcsm.sm': 'La compra de este paquete aumentará la exposición de la tienda y obtendrá más tráfico de clientes. Con el fin de obtener más pedidos y aumentar los ingresos de la tienda. ',

  'flow.tcmc': 'Nombre del paquete',
  'flow.gmsc': 'Duración de la compra',
  'flow.zje': 'Cantidad total',
  'flow.jzsj': 'Fecha límite',
  'flow.gmrq': 'Fecha de compra',
  'flujo.tian1': 'día',
  'flow.zhou1': 'semana',
  'flujo.yue1': 'mes',

  'share.dj': 'nivel',

  'share.hz': 'Transferir',
  'share.hzd': 'Transferir a',
  'share.qbye': 'saldo de la billetera',
  'share.tgje': 'Importe de la promoción',
  'share.hzje': 'Importe de la transferencia',
  'share.qsrhzje': 'Ingrese el monto de la transferencia',

  '_inv._title': 'Descripción de la invitación',
  '_inv._t1': '1.Invitación y relación invitada',
  '_inv._desc1': 'A invita a B, B invita a C, C invita a D. El nivel más alto es 4, si D vuelve a invitar a E, entonces E no tiene nada que ver con A. ',
  '_inv._t2': '2. El que invita recibe una recompensa',
  '_inv._desc2': 'A obtiene el 4% del monto total completado por el pedido B, A obtiene el 2% del monto total completado por el pedido C y A obtiene el 1% del monto total completado por el pedido D',

  '_index._gm': 'Comprar',
  '_index._sm': 'Descripción: Aumente la exposición de la tienda y atraiga tráfico',

  '_shop._update': '{n} veces/mes, modificado {m} veces en el mes actual',

  '_chat._hc': 'Retirar',

  '_footer._suom': 'Descripción de la política',

  '_footer._platform._qualification': 'Cualificación de la empresa',
  '_store._ptzz': 'Cualificaciones relacionadas con la plataforma ShopifyOutlets Global',

  '_abt._gywm': 'Acerca de nosotros',
  '_abt._syms': 'Modelo de negocio',
  '_abt._qyzr': 'Responsabilidad Corporativa',
  '_abt._lxwm': 'Contáctanos',

  '_wsj._wsjms': 'Celebra Halloween con estilo en nuestra espeluznante tienda de Halloween. Ya sea que estés buscando un truco o un regalo durante esta espeluznante temporada, nuestra colección de disfraces, decoraciones y accesorios de Halloween tiene algo para toda la familia. "Haz una declaración escalofriante con nuestras colecciones para mujer y hombre, o da rienda suelta a la imaginación de tu hijo con disfraces. Desde lindos artículos para el hogar y juguetes inolvidables hasta belleza y cosméticos glamurosos, encuentra las piezas perfectas para celebrar las fiestas más emocionantes del año',

  '_wsj._b1': 'Decoración del hogar de otoño',
  '_wsj._b2': 'Calabaza',
  '_wsj._b3': 'Inicio',
  '_wsj._b4': 'Niños',
  '_wsj._b5': 'Bebé',
  '_wsj._b6': 'Mujeres',
  '_wsj._b7': 'Disfraces',
  '_wsj._b8': 'Personalizado',

  '_wsj._cj': 'Participar',
  '_wsj._gz': 'Reglas de actividad',
  '_wsj._flss': 'Búsqueda de categorías',
  '_wsj._wsnz': 'Ropa de mujer de Halloween',
  '_wsj._wsn': 'Ropa de hombre para Halloween',
  '_wsj._wst': 'Ropa infantil de Halloween',
  '_wsj._wszs': 'Decoración de Halloween',
  '_wsj._wslp': 'Regalo de Halloween',

  '_wsj._desc1': '"¡Especial de Halloween, Truco o Trato!" ¡Este octubre hemos preparado una fiesta de carnaval para ti! Ven y compra todo tipo de productos aterradores e impactantes, ¡te esperan grandes descuentos! ',
  '_wsj._lkm': 'Ir de compras',

  '_wsj._wsj': 'Halloween',

  '_wsj._wsjjz': 'Halloween se acerca',
  '_wsj._mzbt': 'Calendarios de adviento de belleza',
  '_wsj._mznr': 'La cuenta atrás para la época más maravillosa del año acaba de llegar con calendarios de adviento para todos los gustos.',

  '_chr._tt1': 'Compra toda la Navidad',
  '_chr._tt2': 'Decoraciones navideñas',
  '_chr._tt3': 'Comprar todos los regalos',
  '_chr._tt4': 'Compra toda la ropa de dormir navideña',

  '_chr._dt1': 'El período previo a la Navidad es tan mágico como el gran día. Así que ordena tus regalos, adorna tus pasillos y prepara tus pijamas a juego para la magia de la Navidad.',

  '_chr._dtt1': 'Ropa de Navidad',
  '_chr._dtt2': 'Navidad familiar a juego',
  '_chr._dtt3': 'Calendarios de Adviento',
  '_chr._dtt4': 'Navidad en Casa',
  '_chr._dtt5': 'Tienda acogedora',
  '_chr._dtt6': 'Muebles a tiempo para Navidad',

  '_chr._tre1': 'Tendencia actual',
  '_chr._tre2': 'Agrega un poco de diversión festiva a tu hogar con las tendencias que a todos les encantan.',
  '_chr._tre3': 'Pan de jengibre',
  '_chr._tre4': 'Pudín de Navidad',
  '_chr._tre5': 'Navidad Gonks',

  '_chr._ttre1': 'Tarjetas y papel de regalo',
  '_chr._ttre2': 'Juguetes y juegos',
  '_chr._ttre3': 'Regalos de comida y bebida',
  '_chr._ttre4': 'Rellenos de medias',
  '_chr._ttre5': 'Papá Noel secreto',
  '_chr._ttre6': 'La primera Navidad del bebé',
  '_chr._ttre7': 'Regalos de Belleza',
  '_chr._ttre8': 'Regalos Premium',
  '_chr._ttre9': 'Regalos personalizados',

  '_sdj._sdnz': 'Ropa de mujer',
  '_sdj._sdn': 'Ropa de hombre',
  '_sdj._sdetlw': 'regalo',
  '_sdj._sdzs': 'Decoración',
  '_sdj._sdjj': 'juguete',

  '_sdj._title1': 'Se acerca la Navidad',
  '_sdj._desc1': '"Navidad: la temporada de generosidad y gratitud." ¡Este diciembre, hemos preparado una fiesta familiar cálida y alegremente decorada para ti! ¡Ven y consigue una variedad de productos brillantes y cálidos con grandes descuentos esperándote! ',

  '_ssd._lhpp': 'Pago flexible',
  '_ssd._lzhq': 'Fuente Global',
  '_ssd._pwyx': 'calificado como excelente',
  '_ssd._qsth': 'Retorno fácil',
  '_ssd._jgppcn': 'Igualación de precios y compromiso',
  '_ssd._ztbt': 'Crear una ventanilla única global',
  '_ssd._yzspl': 'Obtención de inventario directamente de más de 8.000 marcas independientes',
  '_ssd._zcgm': 'Registrarse para comprar',
  '_ssd._zccs': 'Registrarse para la venta',

  '_ssd._dlwz': 'Iniciar sesión y registrarse',

  '_ssd._qjrgwc': 'Agregar todo al carrito',

  '_zddy._gzlb': 'Lista de seguimiento',
  '_zddy._zsersxs': 'Vendido en shopifyoutlets',
  '_zddy._zccwsj': 'Registrarse como comerciante',
  '_zddy._dlyh': 'Iniciar sesión',
  '_zddy._zcyh': 'Registrarse',
  '_zddy._xyh': 'Nuevo usuario',
  '_zddy._gwc': 'Carrito de compras',
  '_zddy._znxx': 'Mensaje',
  '_zddy._zshy': 'Más popular',
  '_zddy._ddl': 'Pedido',
  '_zddy._mysj': 'Sin datos',
  '_zddy._ckgd': 'Ver más',
  '_zddy._tjsp': 'Productos recomendados',
  '_zddy._rmsp': 'Productos populares, nuevas tendencias, más oportunidades de compra',
  '_zddy._xsp': 'Nuevo producto',
  '_zddy._jlzzr': 'Los artículos más populares de las últimas dos semanas',
  '_zddy._ljgm': 'Comprar ahora',
  '_zddy._gdsp': 'Productos más populares',
  '_zddy._ssbt': 'Buscar',
  '_zddy._ssgjz': 'Estoy buscando..',

  '_zddy._bdhb': 'Moneda local',
  '_zddy._xzbdhb': 'Seleccionar moneda local',
  '_zddy._zje': 'Cantidad total',
  '_zddy._xzzffs': 'Por favor, elija el método de pago que más le convenga',
  '_zddy._jmhb': 'Criptomoneda',

  '_zddy._cp': 'producto',
  '_zddy._sj': 'Empresario',
  '_zddy._mj': 'Vendedor',
  '_zddy._fhsy': 'Volver a la página de inicio',

  '_sjsy._ddpl': 'Lista de pedidos',
  '_yhqd._czbz': 'Comuníquese con el servicio de atención al cliente exclusivo para confirmar la información de recarga antes de recargar.',
  '_yhqd._lxkf': 'Contactar Servicio al Cliente',
  '_yhqd._qsrje': 'Por favor ingresa el monto de la recarga',
  '_yhqd._wrhyhtd': 'Sin acceso al banco',
  '_yhqd._yhzz': 'Transferencia bancaria',
  '_yhbt._yhlx': 'tipo de banco',
  '_zcyz._sjdxyz': 'Verificación por SMS móvil',
  '_zcyz._sjhm': 'número de teléfono',
  '_zcyz._srsjhm': 'Introduce el número de móvil',
  '_zzsd._qsrzzje': 'Por favor ingrese el monto de la transferencia',
  '_zzsd._zzje': 'monto de la transferencia',
  '_txcd._qsrtxje': 'Por favor ingrese el monto del retiro',
  '_txcd._skr': 'Tenedor',
  '_txcd._skr._ms': 'Por favor ingrese el nombre del titular de la tarjeta',
  '_txcd._skzh': 'número de cuenta de pago',
  '_txcd._skzh._ms': 'Por favor ingrese el número de cuenta de pago',
  '_txcd._tjyhk': 'Añadir tarjeta bancaria',
  '_txcd._txje': 'Cantidad de retiro',
  '_txcd._wdyhdz': 'Dirección del banco',
  '_txcd._yhdm': 'código',
  '_txcd._yhdm._ms': 'Por favor ingrese el código bancario',
  '_txcd._yhdz': 'DIRECCIÓN',
  '_txcd._yhdz._ms': 'Por favor ingrese la dirección del banco',
  '_txcd._yhlx': 'tipo',
  '_txcd._yhlx._ms': 'Por favor introduzca el tipo de tarjeta bancaria',
  '_txcd._yhly': 'enrutamiento',
  '_txcd._yhly._ms': 'Por favor ingrese la ruta bancaria',
  '_txcd._yhmc': 'banco',
  '_txcd._yhmc._ms': 'Por favor ingrese el nombre del banco',
  '_txcd._yhtx': 'retiro de efectivo del banco',

  '_jys._gmjmhb': 'Comprar criptomonedas',
  '_jys._gmzy': 'Puedes registrar una cuenta y comprar criptomonedas accediendo a los siguientes intercambios y de acuerdo con las políticas actuales de tu país. ¡Entonces recarga a la cuenta de la plataforma! ',

  '_kdydj._bt': 'Abre una tienda y gana grandes premios',
  '_kdydj._xyzbt': 'La suerte sigue girando',
  '_kdydj._yx': 'Bombear {n} veces',
  '_kdydj._xh': 'Consumo {n} puntos',

  '_kdydj._jf': 'Puntos:',
  '_kdydj._zjjl': 'Récord ganador',

  '_kdydj._hdgz': 'Reglas de actividad',
  '_kdydj._qx': 'Por favor',
  '_kdydj._dl': 'Iniciar sesión',

  '_kdydj._mjdl': 'Inicio de sesión del vendedor',
  '_kdydj._yxh': 'Correo electrónico',
  '_kdydj._yxhsm': 'Por favor ingresa tu correo electrónico',
  '_kdydj._yxhmm': 'Por favor ingresa la contraseña',
  '_kdydj._rgmyzh': 'Si no tienes una cuenta',
  '_kdydj._djzc': 'Haga clic para registrarse',
  '_kdydj._wjmm': 'Olvidé mi contraseña',

  '_kdydj._wdjp': 'Mi premio',
  '_kdydj._zjts': 'Consejos para ganar',
  '_kdydj._jx': 'Continuar',
  '_kdydj._czl': 'Ganó',

  '_kdydj._ljjl': 'Recompensa acumulativa',
  '_kdydj._ycy': 'Participó: {n} veces',
  '_kdydj._cy': 'Participa {n} veces',
  '_kdydj._lq': 'Recibir',
  '_kdydj._ylq': 'Recibido',
  '_kdydj._wdd': 'No alcanzado',

  '_dk._dkbt1': 'El préstamo empresarial resuelve el problema de pedir dinero prestado',
  '_dk._dkbt2': 'Proporcionar préstamos empresariales y servicios de circulación de capital',
  '_dk._dkbt3': 'Le ayudaremos a resolver problemas de préstamos, como escasez de fondos, no tener dónde pedir dinero prestado y tener vergüenza de pedir dinero prestado a sus amigos. Brindamos servicios profesionales durante todo el proceso y respondemos sus preguntas en cualquier momento. El objetivo es hacer que cada préstamo sea transparente, permitiendo a los clientes utilizarlos rápidamente y con confianza. ',
  '_dk._zxsq': 'Solicitud en línea',
  '_dk._wddk': 'Mi préstamo',
  '_dk._aqwy': 'Seguridad y sin preocupaciones',
  '_dk._lhed': 'Cuota flexible',
  '_dk._lhedb': 'Monto: 5,00 a 500 000, bajo interés, métodos de pago flexibles, sin cargos iniciales, préstamos en línea, sin llamadas de revisión, confidencialidad de la información y préstamos el mismo día. ',
  '_dk._jsfk': 'Préstamo rápido',
  '_dk._jsfkb': 'Pase la revisión en la hora más rápida y el pago se realizará inmediatamente después de la revisión. ',
  '_dk._aqww1': 'Seguro y sin preocupaciones',
  '_dk._aqww1b': 'Sin préstamo hipotecario, sin depósito requerido, sin devolución de llamadas, este es un asunto privado. ',

  '_dk._gywm': 'Acerca de nosotros',
  '_dk._gywm1': 'Estamos comprometidos con los servicios financieros de préstamos, basados ​​en la filosofía empresarial de "el cliente primero" y con la misión de resolver los problemas de rotación de capital de los clientes. Desde su creación, la empresa ha ayudado a innumerables clientes a resolver la rotación de capital. problemas. ',
  '_dk._gywm2': 'Monto: 500 a 500 000, interés bajo, métodos de pago flexibles, sin cargos iniciales, préstamos en línea, sin llamadas de revisión, confidencialidad de la información y préstamos el mismo día. ',
  '_dk._gywm3': 'Si no quieres pedir dinero prestado a tus compañeros y amigos, por favor ven a nosotros. ',
  '_dk._gywm4': 'Comprometidos con los servicios de préstamo a emprendedores',
  '_dk._gywm5': 'Resuelve tus problemas financieros',
  '_dk._hzhb': 'Socio - Plataforma de préstamos en línea',
  '_dk._smwda': 'Los usuarios de la estación global shopifyoutlets Business provienen de 112 países alrededor del mundo y utilizan la criptomoneda USDT/ETH/BTC/TRX para la liquidación. Las criptomonedas son una forma de comerciar sin fronteras, que permite transacciones instantáneas de bajo costo en todo el mundo, sin esperas ni tarifas internacionales. ',

  '_dk._yjwa': 'shopifyoutlets Business© Co., Ltd. 2022-2024 Todos los derechos reservados',
  '_dk._yjwab': 'ShopifyOutlets Holdings Global Sourcing Limited (0008945) está autorizado y regulado por la Autoridad de Conducta Financiera como corredor de crédito y trabaja exclusivamente con el prestamista Home Retail Group CardServices Limited, operando como shopifyoutlets Business Financial Services, shopifyoutlets Business Financial. Los servicios están autorizados y regulados por la Autoridad de Conducta Financiera para proporcionar crédito. Home Retail GroupCard Services Limited está registrada en Inglaterra (04007072), Avebury, 489-499 Avebury Boulevard, Milton Keynes, Reino Unido. MK9 2NO. ',

  '_dk._cyzjsq': 'Rellene la solicitud de préstamo comercial',
  '_dk._dkll': 'Tasa de interés del préstamo',
  '_dk._dkll2': 'Descripción de la tasa de interés del préstamo (haga clic para mostrar)',
  '_dk._gs1': 'Fórmula',
  '_dk._gs2': 'Interés',
  '_dk._gs3': 'Monto del préstamo',
  '_dk._gs4': 'Tasa de interés anual',
  '_dk._gs5': 'Número de días del préstamo',
  '_dk._gs6': 'por ejemplo',
  '_dk._gs7': 'Monto del préstamo',
  '_dk._gs8': 'Ciclo de préstamo',
  '_dk._gs9': 'día',
  '_dk._fkfs': 'Método de préstamo',
  '_dk._zhye': 'Saldo de la cuenta',
  '_dk._dkqx': 'Período de préstamo',
  '_dk._dkqx1': 'Seleccionar período de préstamo',
  '_dk._sqje': 'Monto de la solicitud',
  '_dk._qsrdk': 'Por favor ingrese el préstamo',
  '_dk._dkje': 'Monto del préstamo',
  '_dk._gj': 'Nacionalidad',
  '_dk._qxzgj': 'Por favor seleccione nacionalidad',
  '_dk._sqr': 'Solicitante',
  '_dk._qsrsqsm': 'Por favor ingrese el nombre del solicitante',
  '_dk._zjhm': 'Número de identificación',
  '_dk._qsrzjhm': 'Por favor ingresa el número de identificación',
  '_dk._zjzp': 'Foto de identificación',
  '_dk._zjzp1': 'Por favor sube una foto del frente de tu identificación',
  '_dk._zjfm1': 'Por favor sube una foto del reverso de tu identificación',
  '_dk._zjsc1': 'Por favor sube una foto de tu documento de identidad',
  '_dk._zjzp2': 'Seleccionar imagen',
  '_dk._pzslt': 'Ejemplo de fotografía',
  '_dk._wytyb': 'He leído y acepto',
  '_dk._jkxy': 'Contrato de Préstamo',
  '_dk._tjsq': 'Enviar solicitud',

  '_dk._wddk2': 'Mi préstamo',
  '_dk._ydk': 'Préstamo',
  '_dk._cslx': 'Generar interés',
  '_dk._yjhk': 'Reembolso estimado',
  '_dk._dkjl': 'Registro de préstamo',
  '_dk._hkjl': 'Registro de pago',
  '_dk._sqrq': 'Fecha de solicitud',
  '_dk._hkzq': 'Ciclo de pago',
  '_dk._gdll': 'Tasa de interés fija',
  '_dk._hkz': 'Reembolso en curso',
  '_dk._yhk': 'Reembolsado',
  '_dk._sqz': 'Aplicando',
  '_dk._ytg': 'Aprobado',
  '_dk._ybh': 'Rechazado',
  '_dk._tqhk': 'Reembolso anticipado',

  '_dk._ljhk': 'Pagar inmediatamente',
  '_dk._qrytqhk': '¿Está seguro de que desea solicitar el reembolso por adelantado?',
  '_dk._qrytqhk1': 'Nota: Después de que la solicitud sea exitosa, el monto depositado se devolverá directamente al préstamo con prioridad',
  '_dk._dkxq': 'Detalles del préstamo',
  '_dk._dkje1': 'Monto del préstamo',
  '_dk._yhlx': 'Intereses adeudados',
  '_dk._hkze': 'Reembolso total',
  '_dk._sqhkz': 'Solicitando reembolso',
  '_dk._zxydbty': 'Por favor lea atentamente y marque la casilla para aceptar el "Contrato de Préstamo"',
  '_dk._zzsqz': 'Aplicando',

  '_dk._dksq': 'Crédito',

  '_dk._cs': 'Parámetros',
  '_dk._nllsm': 'Expresado como porcentaje, por ejemplo: 6,25%',
  '_dk._dktssm': 'El número total de días desde el inicio hasta el final de la aprobación del préstamo',
  '_dk._yndts': 'El número de días de un año: normalmente 365 días',
  '_dk._zlj': 'Cargo por pago atrasado',

  '_xbb._xz': 'Descargar',
  '_xbb._xzapp': 'Descargar la aplicación ShopifyOutlets',
  '_xbb._smewm': 'Escanea el código QR para descargar',
  '_xbb._syfl': 'Todas las categorías',
  '_xbb._gdfl': 'Más',
  '_xbb._sbbt1': 'Entrega rápida',
  '_xbb._sbbt2': 'Los reembolsos están disponibles si hay algún problema',
  '_xbb._sbbt3': 'Devoluciones gratuitas',
  '_xbb._sbbt4': 'Dentro de 90 días',
  '_xbb._tjsp1': 'Productos recomendados',
  '_xbb._tjsp2': 'Muchos descuentos',
  '_xbb._tjsp3': 'Descuento por tiempo limitado',

  '_xbb._asjgw': 'Compras por tienda',
  '_xbb._gdsp': 'Más productos',
  '_xbb._ljgm': 'Comprar ahora',
  '_xbb._ptbz': 'Garantía ShopifyOutlets',
  '_xbb._aqzf': 'Pago seguro',
  '_xbb._aqzf1': 'Un método de pago utilizado por muchos compradores internacionales',
  '_xbb._aqys': 'Seguridad y Privacidad',
  '_xbb._aqys1': 'Respetamos tu privacidad, por lo que tu información personal está segura',
  '_xbb._mjbh': 'Protección al Comprador',
  '_xbb._mjbh1': 'Si tu pedido no se entrega antes de la fecha prevista o no estás satisfecho con el pedido, puedes obtener un reembolso',
  '_xbb._mzsm': 'Advertencia/Descargo de responsabilidad',

  '_xbb._sjxx': 'Empresario',
  '_xbb._ggms': 'Especificación',
  '_xbb._mswa': 'Descripción',
  '_xbb._khpl': 'Comentarios de clientes',
  '_xbb._zmfk': 'Comentarios positivos',
  '_xbb._gzrs': 'Seguir',
  '_xbb._gzrsan': 'Seguir',
  '_xbb._ygzrsan': 'Ya seguido',
  '_xbb._fsxx': 'Mensaje',
  '_xbb._myrpl': 'Aún no hay comentarios',
  '_xbb._llgd': 'Ver más',
  '_xbb._pdcl': 'Promesa',
  '_xbb._myf': 'Envío gratuito',
  '_xbb._sdsj': 'Entregado en aproximadamente 5-10',
  '_xbb._ksjh': 'Entrega rápida',
  '_xbb._bgdstk': 'Reembolso por paquete perdido',
  '_xbb._shbgtk': 'Si el artículo está dañado, reembolso',
  '_xbb._s30gtk': 'Reembolsos si no se envían dentro de los 30 días',
  '_xbb._aqyys': 'Seguridad y Privacidad',
  '_xbb._wjtrxk': 'Pago seguro: No compartiremos su información personal con ningún tercero sin su consentimiento. ',
  '_xbb._sfgrxx': 'Datos personales seguros: protegemos su privacidad y mantenemos sus datos personales seguros y protegidos. ',
  '_xbb._sl': 'Cantidad',
  '_xbb._kcld': 'Inventario',

  '_xbb._sjrz': 'Comerciante instalado',
  '_xbb._hezjh': 'Nuestro programa de socios le brinda una gama completa de soporte y servicios de marketing, y nuestro equipo de servicio al cliente le brindará soporte y asesoramiento profesional para ayudarlo a optimizar la exhibición de sus productos y su estrategia de ventas. ¡Únase a nosotros ahora! ¡Creemos mayores oportunidades de negocio y crezcamos juntos! ',
  '_xbb._xsje': 'Información comercial',
  '_xbb._rgsqjs': 'Si ya eres vendedor',
  '_xbb._qni': 'Por favor',
  '_xbb._djdl': 'Haga clic para iniciar sesión',
  '_xbb._dplog': 'Almacenar LOGO',
  '_xbb._dpmc': 'Nombre de la tienda',
  '_xbb._srdpmc': 'Por favor ingresa el nombre de la tienda',
  '_xbb._zjhm': 'Número de identificación',
  '_xbb._srzjhm': 'Por favor ingresa el número de identificación',
  '_xbb._zsxm': 'nombre real',
  '_xbb._srzsxm': 'Por favor ingresa tu nombre real',
  '_xbb._srgj': 'País',
  '_xbb._srsrgj': 'Por favor ingresa el país',
  '_xbb._zjz': 'Foto de identificación',
  '_xbb._zjzm': 'Anverso del documento',
  '_xbb._zjfm': 'Reverso del certificado',
  '_xbb._sczjz': 'Mantener foto de identificación',
  '_xbb._pssl': 'Ejemplo de fotografía',
  '_xbb._dlyx': 'Correo electrónico de inicio de sesión',
  '_xbb._srdlyx': 'Por favor ingresa tu correo electrónico de inicio de sesión',
  '_xbb._dlmm': 'Contraseña de inicio de sesión',
  '_xbb._srdlmm': 'Por favor ingresa tu contraseña de inicio de sesión',
  '_xbb._qrmm': 'Confirmar contraseña',
  '_xbb._srqrmm': 'Por favor ingrese la contraseña de confirmación',
  '_xbb._yqm': 'código de invitación',
  '_xbb._sryqm': 'Ingresa el código de invitación',
  '_xbb._yxyzm': 'Código de verificación de correo electrónico',
  '_xbb._sryzm': 'Ingrese el código de verificación de correo electrónico',
  '_xbb._yydbty': 'He leído y acepto',
  '_xbb._rzxy': 'Acuerdo de conciliación',

  '_xbb._qgxty': 'Lea atentamente el acuerdo de conciliación y marque la casilla para aceptar',
  '_xbb._sryqmb': 'Por favor ingresa el código de invitación',

  '_xbb._ddpl': 'Comentarios del pedido',
  '_xbb._dsppl': 'Comentarios sobre productos',
  '_xbb._dddpl': 'Comentar el pedido',

  '_xhd._sdj': 'Navidad'
}

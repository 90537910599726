import request from '@/utils/request'

const api = {
  getHalloween: 'activity/getHalloween',
  getHalloweenAct: 'activity/getHalloweenAct',
  getChristmas: 'activity/getChristmas',
  getChristmasAct: 'activity/getChristmasAct',
}

export default {
  getChristmasAct(parameter) {
    return request({
      url: api.getChristmasAct,
      method: 'post',
      data: parameter
    })
  },
  getHalloween(parameter) {
    return request({
      url: api.getHalloween,
      method: 'post',
      data: parameter
    })
  },
  getHalloweenAct(parameter) {
    return request({
      url: api.getHalloweenAct,
      method: 'post',
      data: parameter
    })
  },
  getChristmas(parameter) {
    return request({
      url: api.getChristmas,
      method: 'post',
      data: parameter
    })
  }
}

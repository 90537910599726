<template>
  <div id="root">
    <div></div>
    <div class="home--new-home--UXKZmgj" data-tticheck="main">
      <div class="group-floor" style="padding-top: 0; padding-bottom: 0" data-component-id="10874281" data-floor-name="pc2024-sale-banner-placeholder">
        <div class="pc2024-banner--bannerContainer--2YiKUzf">
          <div class="new-user--boxs--2IFCvDC new-user--animated--1fmQQKN" data-spm="newuser">
            <div>
              <div class="row-container-v3--root--1vbzptw new-user--wrap--3kwZBry" :style="'background-color: rgb(47, 7, 7); background-image: url(' + require('../../static/css5/cate/img1.gif') + '), url(' + require('../../static/css5/cate/img2.webp') + ')'">
                <div class="row-container-v3--content--3MbPSZw">
                  <div class="new-user--boxWrap--39mT0gh">
                    <!-- 第一个 -->
                    <div class="new-user--leftBox--Eiy3xtA" v-if="Act0.length">
                      <div class="new-user--imgContainer--yCAZ2kk">
                        <router-link :to="{ name: 'detail', query: { id: Act0[0].id } }" target="_blank">
                          <div class="rc-modules--image-wrap--54AJPgs rc-modules--square--1vk3B7N rc-modules--width--2vrT05f new-user--img--1iPj-Wd">
                            <img class="rc-modules--image--juUYhtJ" :src="Act0[0].img" />
                          </div>
                        </router-link>
                        <div class="new-user--pricess--jUZn7a6">${{ Act0[0].price }}</div>
                        <div class="new-user--discount--1TdV4fa">{{ $common.cutPriceRate(Act0[0].price, Act0[0].cost_price) }}</div>
                      </div>
                    </div>
                    <section class="new-user--centerBox--uI47511">
                      <h1 class="new-user--title--KyH7dHQ" style="color: rgb(255, 255, 255)">All from US $0.99</h1>
                      <p class="new-user--subTitle--1covBFp" style="color: rgb(255, 255, 255)">New shopper special</p>
                      <div class="shop-now-btn--shopNowBtn--3dBmr6S new-user--shopButton--CnkPk21" style="--btnColor: #ffffff; --btnBgColor: #000000"><span>Shop now</span></div>
                    </section>
                    <section class="new-user--rightBox--7H9vR2x">
                      <!-- 第二个 -->
                      <div class="new-user--leftImg--2VT6scE" v-if="Act0.length">
                        <div class="new-user--price--21aO5Oi">${{ Act0[1].price }}</div>
                        <router-link :to="{ name: 'detail', query: { id: Act0[1].id } }" target="_blank">
                          <div class="new-user--version--1F6gUvR">
                            <div class="rc-modules--image-wrap--54AJPgs rc-modules--square--1vk3B7N rc-modules--width--2vrT05f new-user--rigthImgss--2CIy0rV">
                              <img class="rc-modules--image--juUYhtJ" :src="Act0[1].img" />
                            </div>
                          </div>
                        </router-link>
                      </div>
                      <!-- 第三个 -->
                      <div class="new-user--rigthImg--2CTPGGR" v-if="Act0.length">
                        <div class="new-user--prices--3ic24GH">${{ Act0[2].price }}</div>
                        <router-link :to="{ name: 'detail', query: { id: Act0[2].id } }" target="_blank">
                          <div class="new-user--version--1F6gUvR">
                            <div class="rc-modules--image-wrap--54AJPgs rc-modules--square--1vk3B7N rc-modules--width--2vrT05f new-user--rigthImgss--2CIy0rV">
                              <img class="rc-modules--image--juUYhtJ" :src="Act0[2].img" />
                            </div>
                          </div>
                        </router-link>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pc2024-banner--indicators--3f2FzH8"></div>
        </div>
      </div>
      <div class="common-floor" data-spm="service" data-component-id="10878656" data-floor-name="pc2024-site-info">
        <div class="row-container-v3--root--1vbzptw pc2024-site-info--box--1-SB4OV">
          <div class="row-container-v3--content--3MbPSZw">
            <div class="assembly--box--PJ4qqTx">
              <section class="assembly--infoSection--25OgA4L">
                <img class="assembly--infoImg--2GmAhlH" src="../../static/css5/cate/i3.webp" alt="" />
                <div class="assembly--intoTitle--D2fSPp5"><b>Free 8-day-avg</b></div>
                <div class="assembly--subTitle--1WrQs1w assembly--hiddenSubtitle--B7gB15S">delivery on Choice items</div>
              </section>
              <div class="assembly--line--1ZV3-J2"></div>
              <section class="assembly--infoSection--25OgA4L">
                <img class="assembly--infoImg--2GmAhlH" src="../../static/css5/cate/i4.webp" alt="" />
                <div class="assembly--intoTitle--D2fSPp5"><b>Fast delivery</b></div>
                <div class="assembly--subTitle--1WrQs1w assembly--hiddenSubtitle--B7gB15S">Get refunds for any issues</div>
              </section>
              <div class="assembly--line--1ZV3-J2"></div>
              <section class="assembly--infoSection--25OgA4L">
                <img class="assembly--infoImg--2GmAhlH" src="../../static/css5/cate/i5.webp" alt="" />
                <div class="assembly--intoTitle--D2fSPp5"><b>Free returns</b></div>
                <div class="assembly--subTitle--1WrQs1w assembly--hiddenSubtitle--B7gB15S">Within 90 days</div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <div class="group-floor" style="padding-top: 32px; padding-bottom: 0" data-component-id="10878173" data-floor-name="pc2024-three-grid-placeholder" data-spm-anchor-id="a2g0o.home.0.i9.650c76dbzehOIT">
        <div>
          <div class="row-container-v3--root--1vbzptw">
            <div class="row-container-v3--content--3MbPSZw">
              <div>
                <div class="grid-3--titleContainer--28TIMu1"><span>Today's deals</span></div>
                <div class="grid-3--boxContainer--oxILtrd">
                  <div data-spm="3fornn" class="grid-3--boxWrap--1H8KSyv">
                    <div>
                      <div class="grid-3--content--3GfT3ui" :style="'background-image: url(' + require('../../static/css5/cate/i6.webp') + ')'">
                        <div class="grid-3--titleBox--22VDVjI">
                          <div class="grid-3--mainTitle--2LLlthI" style="color: rgb(255, 255, 255)">Dollar Express</div>
                          <div class="grid-3--subtitle--3QgbQuk" style="--subtitleBgColorDynamic: #ffffff; --subtitleBgColorStatic: #ffffff">
                            <img height="24" width="24" src="../../static/css5/cate/i7.webp" class="grid-3--staticImg--3fhzBhA" alt="mainTitlePic" />
                            <img height="24" width="24" src="../../static/css5/cate/img2.gif" class="grid-3--dynamicImg--2p65-Ol" alt="mainTitlePic" />
                            <span class="grid-3--subtitleText--1VkXLv7" style="color: rgb(0, 0, 0); font-size: 16px">Super Discounts</span>
                            <div class="grid-3--arrowSvg--1jkcmzB" style="--arrowColorDynamic: #191919; --arrowColorStatic: #191919">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path class="grid-3--arrowPath--36EHqLL" fill-rule="evenodd" clip-rule="evenodd" d="M9.01256 6.74916L14.2626 11.9992L9.01256 17.2492V17.2492C9.69598 17.9326 10.804 17.9326 11.4874 17.2492L16.1187 12.6179C16.2828 12.4538 16.375 12.2312 16.375 11.9992C16.375 11.7671 16.2828 11.5445 16.1187 11.3804L11.4874 6.74916C10.804 6.06574 9.69598 6.06574 9.01256 6.74916V6.74916Z" fill="#191919"></path></svg>
                            </div>
                          </div>
                        </div>
                        <div class="grid-3--cardList--1FhdBLg">
                          <div class="loop-container-v2--loopWrap--1Y4ZCUi grid-3--loop--1dPTZQ3">
                            <div class="loop-container-v2--loopBtn--1S5MFzv loop-container-v2--prev--39flsIQ grid-3--prevClassName--4yzjk4k act1-prev"></div>
                            <div class="es--root--3PUGUTI loop-container-v2--container--3okkL54" style="display: flex; flex-direction: row" v-if="Act1.length">
                              <swiper
                                :modules="modules"
                                :slidesPerView="2"
                                :spaceBetween="0"
                                :navigation="{ nextEl: '.act1-next', prevEl: '.act1-prev' }"
                                :loop="true"
                                :autoplay="{
                                  delay: 2000,
                                  disableOnInteraction: false
                                }"
                                style="height: auto; width: 100%"
                              >
                                <swiper-slide v-for="(item, i) in Act1" :key="i">
                                  <div>
                                    <div class="grid-3--cardContainer--3P28L4N">
                                      <router-link :to="{ name: 'detail', query: { id: item.id } }" class="src--cardContent--1QAh3l2" target="_blank">
                                        <div class="grid-3--cardBox--12zkKz3 src--cardContent--1QAh3l2">
                                          <div class="rc-modules--image-wrap--54AJPgs rc-modules--square--1vk3B7N rc-modules--width--2vrT05f grid-3--image--VJVS2wn" title="">
                                            <img class="rc-modules--image--juUYhtJ" :src="item.img" alt="" />
                                          </div>
                                          <div class="src--itemContainer--BuM9YdU">
                                            <div class="src--title--3I7Ij6g src--gridTitle--2EXC2a9">{{ item.name }}</div>
                                            <div style="margin-top: 12px">
                                              <div class="_2024-price--priceContainer--1f6GFX2">
                                                <span class="src--shortenPrice--38zWeO9 _2024-price--minPrice--1TI_LeH" style="color: rgb(237, 56, 63)">${{ item.price }}</span>
                                                <span class="src--shortenMiniPrice--2Y9MJeu _2024-price--oriMinPrice--E52xats">${{ item.cost_price }}</span>
                                              </div>
                                            </div>
                                            <div class="src--crowd--3SS5JJk">New shopper only</div>
                                          </div>
                                        </div>
                                      </router-link>
                                    </div>
                                  </div>
                                </swiper-slide>
                              </swiper>
                            </div>
                            <div class="loop-container-v2--loopBtn--1S5MFzv loop-container-v2--next--LQfdeur grid-3--nextClassName--nP__fyq act1-next"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div data-spm="superdeal" class="grid-3--boxWrap--1H8KSyv">
                    <div>
                      <div class="grid-3--content--3GfT3ui" :style="'background-image: url(' + require('../../static/css5/cate/i8.webp') + ')'">
                        <div class="grid-3--titleBox--22VDVjI">
                          <div class="grid-3--mainTitle--2LLlthI" style="color: rgb(255, 255, 255)">SuperDeals</div>
                          <div class="grid-3--subtitle--3QgbQuk" style="--subtitleBgColorDynamic: #ffffff; --subtitleBgColorStatic: #ffffff">
                            <img height="24" width="24" src="../../static/css5/cate/i9.webp" class="grid-3--staticImg--3fhzBhA" alt="mainTitlePic" />
                            <img height="24" width="24" src="../../static/css5/cate/img3.gif" class="grid-3--dynamicImg--2p65-Ol" alt="mainTitlePic" />
                            <span style="color: rgb(51, 51, 51); font-size: 16px; display: flex">
                              Ends in:
                              <div class="grid-3--countdown--1_oVK04">
                                <div class="countDown grid-3--simpleCountDown--3Vv2Ht3">
                                  <div class="timer">
                                    <span class="dice" data-role="hour" style="font-size: 16px; background: none; font-weight: 500">January 31</span>
                                    <!-- <span class="split" style="margin: 0px; font-size: 16px; font-weight: 500">:</span>
                                    <span class="dice" data-role="minute" style="font-size: 16px; background: none; font-weight: 500">20</span>
                                    <span class="split" style="margin: 0px; font-size: 16px; font-weight: 500">:</span>
                                    <span class="dice" data-role="second" style="font-size: 16px; background: none; font-weight: 500">26</span> -->
                                  </div>
                                </div>
                              </div>
                            </span>
                            <div class="grid-3--arrowSvg--1jkcmzB" style="--arrowColorDynamic: #191919; --arrowColorStatic: #191919">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path class="grid-3--arrowPath--36EHqLL" fill-rule="evenodd" clip-rule="evenodd" d="M9.01256 6.74916L14.2626 11.9992L9.01256 17.2492V17.2492C9.69598 17.9326 10.804 17.9326 11.4874 17.2492L16.1187 12.6179C16.2828 12.4538 16.375 12.2312 16.375 11.9992C16.375 11.7671 16.2828 11.5445 16.1187 11.3804L11.4874 6.74916C10.804 6.06574 9.69598 6.06574 9.01256 6.74916V6.74916Z" fill="#191919"></path></svg>
                            </div>
                          </div>
                        </div>
                        <div class="grid-3--cardList--1FhdBLg">
                          <div class="loop-container-v2--loopWrap--1Y4ZCUi grid-3--loop--1dPTZQ3">
                            <div class="loop-container-v2--loopBtn--1S5MFzv loop-container-v2--prev--39flsIQ grid-3--prevClassName--4yzjk4k act2-prev"></div>
                            <div class="es--root--3PUGUTI loop-container-v2--container--3okkL54" style="display: flex; flex-direction: row" v-if="Act2.length">
                              <swiper
                                :modules="modules"
                                :slidesPerView="2"
                                :spaceBetween="0"
                                :navigation="{ nextEl: '.act2-next', prevEl: '.act2-prev' }"
                                :loop="true"
                                :autoplay="{
                                  delay: 2500,
                                  disableOnInteraction: false
                                }"
                                style="height: auto; width: 100%"
                              >
                                <swiper-slide v-for="(item, i) in Act2" :key="i">
                                  <div>
                                    <div class="grid-3--cardContainer--3P28L4N">
                                      <router-link :to="{ name: 'detail', query: { id: item.id } }" class="src--cardContent--1QAh3l2" target="_blank">
                                        <div class="grid-3--cardBox--12zkKz3 src--cardContent--1QAh3l2">
                                          <div class="rc-modules--image-wrap--54AJPgs rc-modules--square--1vk3B7N rc-modules--width--2vrT05f grid-3--image--VJVS2wn" title="RONGDUOYI Brown Wave Short Bob Wig Synthetic Lace Front Wigs For Women Girls Natural Wave Hair Blue Pink Wigs Daily Wear Cosplay">
                                            <img class="rc-modules--image--juUYhtJ" :src="item.img" alt="" />
                                          </div>
                                          <div class="src--itemContainer--BuM9YdU">
                                            <div class="src--title--3I7Ij6g src--gridTitle--2EXC2a9">{{ item.name }}</div>
                                            <div style="margin-top: 12px">
                                              <div class="_2024-price--priceContainer--1f6GFX2">
                                                <span class="src--shortenPrice--38zWeO9 _2024-price--minPrice--1TI_LeH" style="color: rgb(25, 25, 25)">${{ item.price }}</span>
                                                <span class="src--shortenMiniPrice--2Y9MJeu _2024-price--oriMinPrice--E52xats">${{ item.cost_price }}</span>
                                              </div>
                                            </div>
                                            <div class="src--sdCountContainer--1T0rebt">
                                              <div class="src--sdCount--pgBtll7">
                                                <span>{{ $common.cutPriceRate(item.price, item.cost_price) }}</span>
                                              </div>
                                              <div class="src--sdCountLeft--2LRBQ6z"><span></span></div>
                                            </div>
                                          </div>
                                        </div>
                                      </router-link>
                                    </div>
                                  </div>
                                </swiper-slide>
                              </swiper>
                            </div>
                            <div class="loop-container-v2--loopBtn--1S5MFzv loop-container-v2--next--LQfdeur grid-3--nextClassName--nP__fyq act2-next"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div data-spm="bigsave" class="grid-3--boxWrap--1H8KSyv">
                    <div>
                      <div class="grid-3--content--3GfT3ui" :style="'background-image: url(' + require('../../static/css5/cate/i10.webp') + ')'">
                        <div class="grid-3--titleBox--22VDVjI">
                          <div class="grid-3--mainTitle--2LLlthI" style="color: rgb(255, 255, 255)">Big Save</div>
                          <div class="grid-3--subtitle--3QgbQuk" style="--subtitleBgColorDynamic: #ffffff; --subtitleBgColorStatic: #ffffff">
                            <div class="grid-3--dynamicImg--2p65-Ol grid-3--loopDynamicImg--jGjNzSX">
                              <div class="src--carousel--IE3NBsg" style="">
                                <div id="carousel-list"></div>
                              </div>
                            </div>
                            <span class="grid-3--subtitleText--1VkXLv7" style="color: rgb(11, 9, 9); font-size: 16px">500+ Brands</span>
                            <div class="grid-3--arrowSvg--1jkcmzB" style="--arrowColorDynamic: #191919; --arrowColorStatic: #191919">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path class="grid-3--arrowPath--36EHqLL" fill-rule="evenodd" clip-rule="evenodd" d="M9.01256 6.74916L14.2626 11.9992L9.01256 17.2492V17.2492C9.69598 17.9326 10.804 17.9326 11.4874 17.2492L16.1187 12.6179C16.2828 12.4538 16.375 12.2312 16.375 11.9992C16.375 11.7671 16.2828 11.5445 16.1187 11.3804L11.4874 6.74916C10.804 6.06574 9.69598 6.06574 9.01256 6.74916V6.74916Z" fill="#191919"></path></svg>
                            </div>
                          </div>
                        </div>
                        <div class="grid-3--cardList--1FhdBLg">
                          <div class="loop-container-v2--loopWrap--1Y4ZCUi grid-3--loop--1dPTZQ3">
                            <div class="loop-container-v2--loopBtn--1S5MFzv loop-container-v2--prev--39flsIQ grid-3--prevClassName--4yzjk4k act3-prev"></div>
                            <div class="es--root--3PUGUTI loop-container-v2--container--3okkL54" style="display: flex; flex-direction: row">
                              <swiper
                                :modules="modules"
                                :slidesPerView="2"
                                :spaceBetween="0"
                                :navigation="{ nextEl: '.act3-next', prevEl: '.act3-prev' }"
                                :loop="true"
                                :autoplay="{
                                  delay: 3000,
                                  disableOnInteraction: false
                                }"
                                style="height: auto; width: 100%"
                              >
                                <swiper-slide v-for="(item, i) in Act3" :key="i">
                                  <div>
                                    <div class="grid-3--cardContainer--3P28L4N">
                                      <router-link :to="{ name: 'detail', query: { id: item.id } }" class="src--cardContent--1QAh3l2" target="_blank">
                                        <div class="grid-3--cardBox--12zkKz3 src--cardContent--1QAh3l2">
                                          <div class="rc-modules--image-wrap--54AJPgs rc-modules--square--1vk3B7N rc-modules--width--2vrT05f grid-3--image--VJVS2wn" title="RONGDUOYI Brown Wave Short Bob Wig Synthetic Lace Front Wigs For Women Girls Natural Wave Hair Blue Pink Wigs Daily Wear Cosplay">
                                            <img class="rc-modules--image--juUYhtJ" :src="item.img" alt="" />
                                          </div>
                                          <div class="src--itemContainer--BuM9YdU">
                                            <div class="src--title--3I7Ij6g src--gridTitle--2EXC2a9">{{ item.name }}</div>
                                            <div style="margin-top: 12px">
                                              <div class="_2024-price--priceContainer--1f6GFX2">
                                                <span class="src--shortenPrice--38zWeO9 _2024-price--minPrice--1TI_LeH" style="color: rgb(25, 25, 25)">${{ item.price }}</span>
                                                <span class="src--shortenMiniPrice--2Y9MJeu _2024-price--oriMinPrice--E52xats">${{ item.cost_price }}</span>
                                              </div>
                                            </div>
                                            <div class="src--bsContainer--3vfpT8u">
                                              You save
                                              <!-- <div style="font-weight: 600">$63.12</div> -->
                                            </div>
                                          </div>
                                        </div>
                                      </router-link>
                                    </div>
                                  </div>
                                </swiper-slide>
                              </swiper>
                            </div>
                            <div class="loop-container-v2--loopBtn--1S5MFzv loop-container-v2--next--LQfdeur grid-3--nextClassName--nP__fyq act3-next"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="common-floor" style="padding-top: 32px; padding-bottom: 0" data-spm="business_new" data-component-id="10978261" data-floor-name="pc2024-bussiness">
        <div class="row-container-v3--root--1vbzptw">
          <div class="row-container-v3--content--3MbPSZw">
            <div class="pc2024-bussiness--container--2a0X4tJ" :style="'background: center / cover no-repeat url(' + require('../../static/css5/cate/img3.webp') + '); width: 100%'">
              <div class="Header--row--3Ke2uGK" style="justify-content: space-between">
                <div class="Header--left--1W79WFh">
                  <!-- <img class="Header--logo--keb_qkc" src="https://ae01.alicdn.com/kf/S62c55ad0e62240388580c48b826b7aee7/591x82.png" alt="Bussiness logo" /> -->
                  <div class="Header--row--3Ke2uGK" style="flex-wrap: wrap; margin-top: 8px; gap: 8px 16px">
                    <div class="Header--mItem--1WkOzJ9"><img class="Header--mIcon--ONysZxn" src="../../static/css5/cate/i11.webp" /><span class="Header--mText--3G7JRdB">Tax exemptions</span></div>
                    <div class="Header--mItem--1WkOzJ9"><img class="Header--mIcon--ONysZxn" src="../../static/css5/cate/i12.webp" /><span class="Header--mText--3G7JRdB">Express Payments</span></div>
                    <div class="Header--mItem--1WkOzJ9"><img class="Header--mIcon--ONysZxn" src="../../static/css5/cate/i13.webp" /><span class="Header--mText--3G7JRdB">Financial Support</span></div>
                  </div>
                  <div style="--btnColor: #111111; --btnBgColor: #ffffff" class="shop-now-btn--shopNowBtn--3dBmr6S Header--btn--cvKCdJU"><span>Shop now</span></div>
                </div>
                <div class="Header--right--1yzlzNz">
                  <div class="Header--row--3Ke2uGK Header--sCard--2W9-MFT" style="gap: 8px">
                    <div class="Header--sLine--2c00ezx"></div>
                    <div class="Header--sItem--3dGphNc"><span class="Header--sTitle--1hTpQvC">5M+</span><span class="Header--mText--3G7JRdB">Factory direct supply</span></div>
                  </div>
                  <div class="Header--row--3Ke2uGK Header--sCard--2W9-MFT" style="gap: 8px">
                    <div class="Header--sLine--2c00ezx"></div>
                    <div class="Header--sItem--3dGphNc"><span class="Header--sTitle--1hTpQvC">20M+</span><span class="Header--mText--3G7JRdB">Value dropshipping items</span></div>
                  </div>
                  <div class="Header--row--3Ke2uGK Header--sCard--2W9-MFT" style="gap: 8px">
                    <div class="Header--sLine--2c00ezx"></div>
                    <div class="Header--sItem--3dGphNc"><span class="Header--sTitle--1hTpQvC">10</span><span class="Header--mText--3G7JRdB">Local warehouses worldwide</span></div>
                  </div>
                  <div class="Header--row--3Ke2uGK Header--sCard--2W9-MFT" style="gap: 8px">
                    <div class="Header--sLine--2c00ezx"></div>
                    <div class="Header--sItem--3dGphNc"><span class="Header--sTitle--1hTpQvC">24H</span><span class="Header--mText--3G7JRdB">Personalized sourcing service</span></div>
                  </div>
                </div>
              </div>
              <div class="ModRow--row--rYcb9Ia ModRow--MRowTop--jz28gRS">
                <div class="ModRow--mCard--21Puayl">
                  <div class="ModRow--mTitle--18A5pfL">Buy in bulk</div>
                  <!-- 活动4 -->
                  <div class="ModRow--row--rYcb9Ia ModRow--smGap--2_nb485" v-if="Act4.length">
                    <router-link :to="{ name: 'detail', query: { id: Act4[0].id } }" class="ItemCard--item--sjHF5A7 ItemCard--itemPd--3FdWHT_" target="_blank">
                      <div class="ItemCard--imgsm--27e6Y0P" style="position: relative">
                        <div class="ItemCard--mask--FLj3L7A"></div>
                        <img class="ItemCard--imgsm--27e6Y0P" :src="Act4[0].img" />
                      </div>
                      <div class="ItemCard--itemInfo--3XWVpz9" style="padding: 0px 4px">
                        <div class="ItemCard--pRow--1RLQ5fX" style="order: 1">
                          <span class="ItemCard--shortenPrice--3l8FALD">${{ Act4[0].price }}</span>
                          <span class="ItemCard--shortenOriginPrice--3jnF7Ey">${{ Act4[0].cost_price }}</span>
                        </div>
                      </div>
                    </router-link>
                    <router-link :to="{ name: 'detail', query: { id: Act4[1].id } }" class="ItemCard--item--sjHF5A7 ItemCard--itemPd--3FdWHT_" target="_blank">
                      <div class="ItemCard--imgsm--27e6Y0P" style="position: relative">
                        <div class="ItemCard--mask--FLj3L7A"></div>
                        <img class="ItemCard--imgsm--27e6Y0P" :src="Act4[1].img" />
                      </div>
                      <div class="ItemCard--itemInfo--3XWVpz9" style="padding: 0px 4px">
                        <div class="ItemCard--pRow--1RLQ5fX" style="order: 1">
                          <span class="ItemCard--shortenPrice--3l8FALD">${{ Act4[1].price }}</span>
                          <span class="ItemCard--shortenOriginPrice--3jnF7Ey">${{ Act4[1].cost_price }}</span>
                        </div>
                      </div>
                    </router-link>
                  </div>
                </div>
                <div class="ModRow--mCard--21Puayl">
                  <div class="ModRow--mTitle--18A5pfL">Fast delivery</div>
                  <!-- 活动5 -->
                  <div class="ModRow--row--rYcb9Ia ModRow--smGap--2_nb485" v-if="Act5.length">
                    <router-link :to="{ name: 'detail', query: { id: Act5[0].id } }" class="ItemCard--item--sjHF5A7 ItemCard--itemPd--3FdWHT_" target="_blank">
                      <div class="ItemCard--imgsm--27e6Y0P" style="position: relative">
                        <div class="ItemCard--mask--FLj3L7A"></div>
                        <img class="ItemCard--imgsm--27e6Y0P" :src="Act5[0].img" />
                      </div>
                      <div class="ItemCard--itemInfo--3XWVpz9" style="padding: 0px 4px">
                        <div class="ItemCard--pRow--1RLQ5fX" style="order: 1">
                          <span class="ItemCard--shortenPrice--3l8FALD">${{ Act5[0].price }}</span>
                          <span class="ItemCard--shortenOriginPrice--3jnF7Ey">${{ Act5[0].cost_price }}</span>
                        </div>
                      </div>
                    </router-link>
                    <router-link :to="{ name: 'detail', query: { id: Act5[1].id } }" class="ItemCard--item--sjHF5A7 ItemCard--itemPd--3FdWHT_" target="_blank">
                      <div class="ItemCard--imgsm--27e6Y0P" style="position: relative">
                        <div class="ItemCard--mask--FLj3L7A"></div>
                        <img class="ItemCard--imgsm--27e6Y0P" :src="Act5[1].img" />
                      </div>
                      <div class="ItemCard--itemInfo--3XWVpz9" style="padding: 0px 4px">
                        <div class="ItemCard--pRow--1RLQ5fX" style="order: 1">
                          <span class="ItemCard--shortenPrice--3l8FALD">${{ Act5[1].price }}</span>
                          <span class="ItemCard--shortenOriginPrice--3jnF7Ey">${{ Act5[1].cost_price }}</span>
                        </div>
                      </div>
                    </router-link>
                  </div>
                </div>
                <div class="ModRow--mCard--21Puayl">
                  <div class="ModRow--mTitle--18A5pfL">Buy again</div>
                  <!-- 活动6 -->
                  <div class="ModRow--row--rYcb9Ia ModRow--smGap--2_nb485" v-if="Act6.length">
                    <router-link :to="{ name: 'detail', query: { id: Act6[0].id } }" class="ItemCard--item--sjHF5A7 ItemCard--itemPd--3FdWHT_" target="_blank">
                      <div class="ItemCard--imgsm--27e6Y0P" style="position: relative">
                        <div class="ItemCard--mask--FLj3L7A"></div>
                        <img class="ItemCard--imgsm--27e6Y0P" :src="Act6[0].img" />
                      </div>
                      <div class="ItemCard--itemInfo--3XWVpz9" style="padding: 0px 4px">
                        <div class="ItemCard--pRow--1RLQ5fX" style="order: 1">
                          <span class="ItemCard--shortenPrice--3l8FALD">${{ Act6[0].price }}</span>
                          <span class="ItemCard--shortenOriginPrice--3jnF7Ey">${{ Act6[0].cost_price }}</span>
                        </div>
                      </div>
                    </router-link>
                    <router-link :to="{ name: 'detail', query: { id: Act6[1].id } }" class="ItemCard--item--sjHF5A7 ItemCard--itemPd--3FdWHT_" target="_blank">
                      <div class="ItemCard--imgsm--27e6Y0P" style="position: relative">
                        <div class="ItemCard--mask--FLj3L7A"></div>
                        <img class="ItemCard--imgsm--27e6Y0P" :src="Act6[1].img" />
                      </div>
                      <div class="ItemCard--itemInfo--3XWVpz9" style="padding: 0px 4px">
                        <div class="ItemCard--pRow--1RLQ5fX" style="order: 1">
                          <span class="ItemCard--shortenPrice--3l8FALD">${{ Act6[1].price }}</span>
                          <span class="ItemCard--shortenOriginPrice--3jnF7Ey">${{ Act6[1].cost_price }}</span>
                        </div>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="common-floor" style="padding-top: 32px; padding-bottom: 0" data-spm="local_activity" data-component-id="10878352" data-floor-name="pc2024-national-floor"></div>
      <div class="group-floor" style="padding-top: 32px; padding-bottom: 0" data-component-id="10946765" data-floor-name="pc2024-category-placeholder">
        <div>
          <p class="pc2024-viva-category--title--cpfSbAI">Shop by category</p>
          <div class="row-container-v3--root--1vbzptw">
            <div class="row-container-v3--content--3MbPSZw">
              <div data-spm="viva" style="background-color: #1b5f48">
                <div>
                  <div class="viva-industry--main--1g3PG_u">
                    <div class="viva-industry--title-industry--QFy8Tgk">
                      <img class="viva-industry--imgurl--3fxFfvM" src="../../static/css5/cate/i14.webp" />
                      <p class="viva-industry--subtitle--3WQG3vn" style="color: rgb(255, 255, 255)">Your fashion choice</p>
                      <div class="shop-now-btn--shopNowBtn--3dBmr6S viva-industry--buttons--aTezBW2" style="--btnColor: #000000; --btnBgColor: #ffffff"><span>Shop now</span></div>
                    </div>
                    <img class="viva-industry--imgs--2q_qYDH" src="../../static/css5/cate/i15.webp" />
                    <div class="viva-industry--carousel--20RNbe4">
                      <div class="loop-container-v2--loopWrap--1Y4ZCUi viva-industry--loop--1NsGTtz">
                        <div class="es--root--3PUGUTI loop-container-v2--container--3okkL54 viva-industry--loops--1_QeJ2N" style="display: flex; flex-direction: row">
                          <div v-for="(item, i) in Act7" :key="i">
                            <div class="card--frame--LR7NwJf">
                              <router-link :to="{ name: 'detail', query: { id: item.id } }" class="card--carousels--2TQC0pg" target="_blank">
                                <div class="card--carousel-image--tsrY151">
                                  <div class="card--hidden--f9MnEK8"></div>
                                  <div class="rc-modules--image-wrap--54AJPgs rc-modules--square--1vk3B7N rc-modules--width--2vrT05f card--carousel-imges--3XUkCSr" title="">
                                    <img class="rc-modules--image--juUYhtJ" :src="item.img" />
                                    <div class="lazy-load-placeholder" style="height: 0px; display: inline-block"></div>
                                  </div>
                                  <div class="card--framesProice--3_gAmT1">
                                    <div class="_2024-price--priceContainer--1f6GFX2">
                                      <span class="card--shotMinPrice--3KBmp62 _2024-price--minPrice--1TI_LeH">${{ item.price }}</span>
                                      <span class="card--shotoriMinPrice--ap64-_A _2024-price--oriMinPrice--E52xats">${{ item.cost_price }}</span>
                                    </div>
                                  </div>
                                  <div class="card--carousel-discount--3eWTqO-">
                                    <div class="review-star--startContainer--3q0sqSR" style="display: flex; align-items: center; justify-content: center">
                                      <img src="../../static/css5/cate/i16.webp" />
                                      <span class="review-star--reviewStart--2eqjxRm card--reviewStar--MRz0mKU">{{ item.star }}</span>
                                    </div>
                                    <div class="card--version--1OPA9Bm"></div>
                                    <div class="card--frames--1UtLu4M" style="margin-left: 8px; margin-right: 8px">
                                      <div class="trade-count--tradeCountWrapper--e0JhCiO">
                                        <div class="trade-count--tradeCount--1JPdiSm">
                                          <span>{{ item.star }}</span>
                                        </div>
                                        <div class="trade-count--sold--2sGDF4B"><span>sold</span></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div data-spm="category" class="viva-category--carousel-container--25TvWPu">
                <swiper :modules="modules" :slidesPerView="1" :spaceBetween="0" :navigation="{ nextEl: '.cate-next', prevEl: '.cate-prev' }" :loop="true" style="height: auto; width: 100%">
                  <swiper-slide>
                    <div class="viva-category--carousel-content--35Ezl-x">
                      <a href="#/category?level=3&id=444" target="_blank">
                        <div :style="'background-image: url(' + require('../../static/css5/cate/1.webp') + ')'" class="viva-category--carousel-item--2gKSlmp">
                          <div class="viva-category--overlay--1K_w2EO"></div>
                          <p class="viva-category--title--2kX9hSS">Beauty &amp; Health</p>
                        </div>
                      </a>
                      <a href="#/category?level=3&id=402" target="_blank">
                        <div :style="'background-image: url(' + require('../../static/css5/cate/2.webp') + ')'" class="viva-category--carousel-item--2gKSlmp">
                          <div class="viva-category--overlay--1K_w2EO"></div>
                          <p class="viva-category--title--2kX9hSS">Sports &amp; Entertainment</p>
                        </div>
                      </a>
                      <a href="#/category?level=3&id=75" target="_blank">
                        <div :style="'background-image: url(' + require('../../static/css5/cate/3.webp') + ')'" class="viva-category--carousel-item--2gKSlmp">
                          <div class="viva-category--overlay--1K_w2EO"></div>
                          <p class="viva-category--title--2kX9hSS">Plus Sized Clothing</p>
                        </div>
                      </a>
                      <a href="#/category?level=3&id=373" target="_blank">
                        <div :style="'background-image: url(' + require('../../static/css5/cate/4.webp') + ')'" class="viva-category--carousel-item--2gKSlmp">
                          <div class="viva-category--overlay--1K_w2EO"></div>
                          <p class="viva-category--title--2kX9hSS">Toys &amp; Games</p>
                        </div>
                      </a>
                      <a href="#/category?level=3&id=339" target="_blank">
                        <div :style="'background-image: url(' + require('../../static/css5/cate/5.webp') + ')'" class="viva-category--carousel-item--2gKSlmp">
                          <div class="viva-category--overlay--1K_w2EO"></div>
                          <p class="viva-category--title--2kX9hSS">Luggage, Bags &amp; Shoes</p>
                        </div>
                      </a>
                      <a href="#/category?level=3&id=516" target="_blank">
                        <div :style="'background-image: url(' + require('../../static/css5/cate/6.webp') + ')'" class="viva-category--carousel-item--2gKSlmp">
                          <div class="viva-category--overlay--1K_w2EO"></div>
                          <p class="viva-category--title--2kX9hSS">Automotive &amp; Motorcycle</p>
                        </div>
                      </a>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div class="viva-category--carousel-content--35Ezl-x">
                      <a href="#/category?level=3&id=191" target="_blank">
                        <div :style="'background-image: url(' + require('../../static/css5/cate/7.webp') + ')'" class="viva-category--carousel-item--2gKSlmp">
                          <div class="viva-category--overlay--1K_w2EO"></div>
                          <p class="viva-category--title--2kX9hSS">Electronics</p>
                        </div>
                      </a>
                      <a href="#/category?level=3&id=305" target="_blank">
                        <div :style="'background-image: url(' + require('../../static/css5/cate/8.webp') + ')'" class="viva-category--carousel-item--2gKSlmp">
                          <div class="viva-category--overlay--1K_w2EO"></div>
                          <p class="viva-category--title--2kX9hSS">Pet Supplies</p>
                        </div>
                      </a>
                      <a href="#/category?level=3&id=151" target="_blank">
                        <div :style="'background-image: url(' + require('../../static/css5/cate/9.webp') + ')'" class="viva-category--carousel-item--2gKSlmp">
                          <div class="viva-category--overlay--1K_w2EO"></div>
                          <p class="viva-category--title--2kX9hSS">Computer, Office &amp; Education</p>
                        </div>
                      </a>
                      <a href="#/category?level=3&id=110" target="_blank">
                        <div :style="'background-image: url(' + require('../../static/css5/cate/10.webp') + ')'" class="viva-category--carousel-item--2gKSlmp">
                          <div class="viva-category--overlay--1K_w2EO"></div>
                          <p class="viva-category--title--2kX9hSS">Phones &amp; Telecommunications</p>
                        </div>
                      </a>
                      <a href="#/category?level=3&id=541" target="_blank">
                        <div :style="'background-image: url(' + require('../../static/css5/cate/11.webp') + ')'" class="viva-category--carousel-item--2gKSlmp">
                          <div class="viva-category--overlay--1K_w2EO"></div>
                          <p class="viva-category--title--2kX9hSS">Home &amp; Garden</p>
                        </div>
                      </a>
                      <a href="#/category?level=3&id=68" target="_blank">
                        <div :style="'background-image: url(' + require('../../static/css5/cate/12.webp') + ')'" class="viva-category--carousel-item--2gKSlmp">
                          <div class="viva-category--overlay--1K_w2EO"></div>
                          <p class="viva-category--title--2kX9hSS">Men's Clothing</p>
                        </div>
                      </a>
                    </div>
                  </swiper-slide>
                </swiper>
                <div>
                  <button class="viva-category--left-button--vstiMkT cate-prev"></button>
                  <button class="viva-category--right-button--2Nb2nlo cate-next"></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="common-floor" data-spm="moretolove" data-component-id="10935253" data-floor-name="pc2023-more-to-love">
        <div class="row-container-v3--root--1vbzptw">
          <div class="row-container-v3--content--3MbPSZw">
            <div data-spm="moretolove" class="pc2023-more-to-love--motolove-warp--3jVaS6A pc2023-more-to-love--newMoveLoveWarp--2g27ynu">
              <div class="pc2023-more-to-love--motolove-title--1R4lXge"><div class="pc2023-more-to-love--title-text--1hXTLae">More to love</div></div>
              <div class="_1wVX7" data-spm="pcJustForYou" style="margin-left: -8px; margin-right: -8px">
                <div v-for="(item, i) in goodsList" :key="i" class="_2FypS" style="max-width: 16.6667%; padding-left: 8px; padding-right: 8px; flex-basis: 16.6667%">
                  <div class="_1Hxqh">
                    <div class="SeJ8l _1Kdtt card-out-wrapper" style="">
                      <router-link :to="{ name: 'detail', query: { id: item.id } }" class="_1UZxx" target="_blank">
                        <div class="_2bIiW _2xJR2" style="">
                          <img class="_1IH3l product-img" :src="item.img" alt="" aria-hidden="true" />
                          <div class="_8Ut19"></div>
                          <!-- <div class="darm7 _1kiOX">
                            <div class="_1E-TZ"><span class="HQGC-"></span></div>
                          </div> -->
                        </div>
                        <div class="_11nFI">
                          <div title="" class="G7dOC" style="margin-top: 8px">
                            <h3 class="nXeOv">{{ item.name }}</h3>
                          </div>
                          <div class="_3TqP9">
                            <div class="t9_CA">
                              <div class="Ktfxu" v-for="i in parseInt(item.star)" :key="i">
                                <div class="_2E4Wz" dir="ltr" style="width: 10px"></div>
                                <img
                                  class="EYoOU"
                                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAeCAYAAAE6DSy/AAAABGdBTUEAALGPC/xhBQAAAGxlWElmTU0AKgAAAAgABAEaAAUAAAABAAAAPgEbAAUAAAABAAAARgEoAAMAAAABAAIAAIdpAAQAAAABAAAATgAAAAAAAACQAAAAAQAAAJAAAAABAAKgAgAEAAAAAQAAACCgAwAEAAAAAQAAAB4AAAAA2L2NEgAAAAlwSFlzAAAWJQAAFiUBSVIk8AAAArdJREFUSA2tl81qFFEQhSeJEDEYQYT4g+DCEFwoPoQbA7pwl1UIeQ5fIA/gIyQEJDggKG7VbESEIEQ0hmyjO0FBicTvdN+6Vt++PdOdTkFN1a0659Ttnv6ZGRz/t6tKB2F9OMBiQQuzSUssThQwWyn6gnJRLjjAmxrCxgotWzGZclnu67qTqKcRWW8V6pV9G5j4PuJdUelGsj6uHOgEJqZApuBHPLQicdvlY1Ivl0LVK0YQ13LNotakQH1XvbhJ8kUVZEHtSVQoy/VPG0tntd4tK+mXGXFGtkhjOTZdIoFNA3WNcM/aDqa7koVHYGgCtqm5NkKAd4wQz3IgXmkjAOZ2xJmSYiyGhNIS/hmvDGL9GC8sPQSrW7xG8tIWuThKYNV2lCNarVHAyIqAV4yQxiaBZS8QRFJusc4JvE3JQeABjMNURQKXcoQ2NbgbbXCNGARuaQfDRsSIBrxpPD7RX4/A1lrwZkWW+ZO4U0NmCnDmCmb48AK1SznDF36tr8DdJoHd3MRczQv4u2zdgwFN4roTl3w9zb3AX2tC0ttH63l8nbVs0fo+Vl4+vjEmP6C/wLvqj3CI65H4Cb+BdzJ/BG2J+wDnbbhI5L9Vw9XrZF038BV1HflROiXUFqgL09q6bOALqtnhNs1tQthWZtfAL9AfcBH3XNxD9AfrExvXxyzkm8H1NSlXvIOf1wXU9k0G/nStmM3HED9zutLj1TQTf+GfZa8onBtP7YfQDFyzCvMbsNo7kov9xtTZ0sSlXbHcBgxw4h9t6XgEN000jaNuw+epUI91o5bdhqm2nuMz3IJ6wvU2jlqP6p/4VCrWdAa22gxHeEqeiqbroLWV1rVu2sCzHJhhOmOP8I+4fgHokXykHFNNveLfVIb/NFMr/3aJndg31vfwGfw+vo13NXH0Y/AyLq3veM3+AXQngyvegYcWAAAAAElFTkSuQmCC"
                                  width="10"
                                  height="10"
                                  alt=""
                                />
                              </div>
                            </div>
                            <span class="Ktbl2">{{ item.orders }} sold</span>
                          </div>
                          <div class="_1okBC">
                            <div class="U-S0j">
                              <span style="font-size: 12px">${{ item.price }}</span>
                              <!-- <span style="font-size: 24px">11</span> -->
                              <!-- <span style="font-size: 12px">.</span> -->
                              <!-- <span style="font-size: 12px">09</span> -->
                            </div>
                            <div class="_1zEQq">
                              <span style="text-decoration: line-through; color: rgb(153, 153, 153)">${{ item.cost_price }}</span>
                            </div>
                          </div>
                          <div class="T8SnT">
                            <img aria-hidden="true" dir="" class="_1lYat _1jUmO" src="../../static/css5/cate/i17.webp" height="16" width="74.5" />
                            <span class="_1juYg"></span>
                            <span class="_3hksz" title="-77%">{{ $common.cutPriceRate(item.price, item.cost_price) }}</span>
                          </div>
                          <div class="_3vRdz">
                            <img aria-hidden="true" dir="" class="_1lYat" src="../../static/css5/cate/i18.webp" height="16" width="38.5" />
                            <span class="_1BSEX _3dc7w _1Z6Rx" title="Free shipping" style="color: rgb(25, 25, 25)">Free shipping</span>
                          </div>
                        </div>
                        <!-- <div class="eFkEW rcmd-hover-more-action">
                          <div class="_3W0hl"><span title="See preview" class="_3bWs9">See preview</span></div>
                          <div class="_3W0hl"><span title="Similar items" class="_3bWs9">Similar items</span></div>
                        </div> -->
                      </router-link>
                    </div>
                  </div>
                </div>
                <!--  -->
              </div>
              <div class="pc2023-more-to-love--view-more--1RYG5yL"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
import { Navigation, Autoplay } from 'swiper'
export default {
  name: 'ChristmasVue',
  data() {
    return {
      //加载更多
      scrollDisabled: false,
      loadmore: false,
      goodsList: [],
      page: 1,

      modules: [Navigation, Autoplay],
      Act0: [],
      Act1: [],
      Act2: [],
      Act3: [],
      Act4: [],
      Act5: [],
      Act6: [],
      Act7: []
    }
  },
  created() {
    this.getChristmasAct()
    this.page = 1
    this.getChristmas()
  },
  methods: {
    getChristmasAct() {
      let that = this
      this.$api.activity.getChristmasAct().then((res) => {
        that.Act0 = res.data[0]
        that.Act1 = res.data[1]
        that.Act2 = res.data[2]
        that.Act3 = res.data[3]
        that.Act4 = res.data[4]
        that.Act5 = res.data[5]
        that.Act6 = res.data[6]
        that.Act7 = res.data[7]
      })
    },
    getChristmas() {
      if (this.loadmore) {
        return
      }
      this.loadmore = true

      let that = this
      this.$api.activity.getChristmas({ page: this.page }).then((res) => {
        that.page++
        res.data.forEach(function (item) {
          that.goodsList.push(item)
        })
        that.loadmore = false
      })
    }
  }
}
</script>

<style scoped>
@import url('@/static/css5/christmas/1.css');
</style>
<style>
.assembly--infoSection--25OgA4L {
  /* display: flex;
  align-items: center;
  justify-content: center; */
}
</style>

<template>
  <div class="nav-header">
    <!-- <span class="nv-title">{{ $t('store.setting') }}</span> -->
    <!-- <span class="nv-desc">这是订单的管理页面，可管理用户的订单</span> -->
  </div>
  <!-- 3 -->
  <div class="top-banner-container" style="margin: 3px 3px" v-if="key == 'halloween'">
    <div class="top-banner" :style="'background-image: url(' + require('@/static/img2/120.png') + '); background-size: 100% 100%'">
      <div class="tb-box">
        <router-link class="tb-join" :to="{ name: 'StoreAddFromHouse', query: { search: 'halloween' } }"> {{ $t('_wsj._cj') }} </router-link>
        <!-- <router-link class="tb-rule" :to="{ name: 'storeDocs', query: { key: 'halloween' } }"> {{ $t('_wsj._gz') }} </router-link> -->
      </div>
    </div>
  </div>

  <div class="top-banner-container" style="margin: 3px 3px; position: relative" v-if="key == 'christmas'">
    <div :style="'width:100%;height:70px;background-color: rgb(47, 7, 7); background-image: url(' + require('@/static/css5/cate/img1.gif') + '), url(' + require('@/static/css5/cate/img2.webp') + ')'">
      <section class="new-user--centerBox--uI47511" style="height: 100%; width: 100%">
        <div class="new-user--title--KyH7dHQ" style="color: rgb(255, 255, 255); height: 100%; width: 100%; display: grid; align-items: center; justify-content: flex-start;padding-left: 50px;">
          <h1 class="ssh1">Participate in Christmas and get more profits!</h1>
          <span style="font-size: 15px">Hurry up and choose products in the Christmas category to participate.</span>
        </div>

        <div class="tb-box">
          <router-link class="tb-join" :to="{ name: 'StoreAddFromHouse', query: { search: 'christmas' } }"> {{ $t('_wsj._cj') }} </router-link>
        </div>
      </section>
    </div>

    <!-- <div class="top-banner" :style="'background-image: url(' + require('@/static/img2/121.png') + '); background-size: 100% 100%'">
      <div class="tb-box">
        <router-link class="tb-join" :to="{ name: 'StoreAddFromHouse', query: { search: 'christmas' } }"> {{ $t('_wsj._cj') }} </router-link>
      </div>
    </div> -->
  </div>

  <a-card>
    <!-- <div style="margin-bottom: 10px; text-align: center">
      <h3>{{ docs.title }}</h3>
    </div> -->
    <a-form name="basic" :label-col="{ span: 4 }" :wrapper-col="{ span: 24 }" v-if="docs">
      <div class="b-content" style="" v-html="docs.content"></div>
    </a-form>
  </a-card>
</template>

<script>
export default {
  name: 'settingVue',
  components: {},
  data() {
    return {
      key: '',
      docs: {}
    }
  },
  watch: {
    $route(vNew) {
      console.log(vNew)
      this.key = this.$route.query.key
      this.getDocs()
    }
  },
  created() {
    this.key = this.$route.query.key
    this.getDocs()
  },
  methods: {
    getDocs() {
      if (this.key) {
        this.$api.docs.getDocs({ key: this.key }).then((res) => {
          this.docs = res.data
        })
      }
    }
  }
}
</script>

<style scoped>
.b-content >>> td {
  border: 1px solid #dfdfdf !important;
}

.b-content >>> p {
  padding: 0 !important;
}

.tb-box {
  margin-right: 10%;
  position: absolute;
  top: calc(100% / 2 - 10px);
  right: 5%;
}

.tb-join {
  border: 3px solid #fff;
  padding: 1px 10px;
  border-radius: 10px;
  color: #fff;
  /* position: absolute; */
  /* right: 13%; */
}

.top-banner {
  position: relative;
  height: 60px;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.ss1 {
  color: rgb(255, 255, 255);
  height: 100%;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
}

.ssh1 {
  color: #fff;
  line-height: initial;
  padding: initial;
  margin: initial;
}
</style>
